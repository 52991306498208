import BulkAlertMobileDataIndividual from './BulkAlertMobileDataIndividual';

export default function BulkAlertMobileData({ data }) {
  const macroData = [];
  // Create combinations of country and importance
  data &&
    data.macroeconomicEvents &&
    data.macroeconomicEvents.forEach((alert) => {
      alert.countryRegion?.forEach((country) => {
        alert.importance?.forEach((importance) => {
          macroData.push({
            alert,
            country,
            importance,
            endDate: alert.endDate,
          });
        });
      });
    });

  const earningsData = data?.earningsEvents;

  const getFormattedData = (item) => {
    if (item === 'macroeconomicEvents') {
      return macroData;
    } else {
      return earningsData;
    }
  };

  return (
    <>
      {Object.keys(data).map((item) => {
        return <BulkAlertMobileDataIndividual data={getFormattedData(item)} text={item} />;
      })}
    </>
  );
}
