import styles from './AlertCountryFilter.module.css';
import { countryCodeToNameMap } from '../../../../utils/countryCodeNameMapping';
import { supportedCountryCodes } from '../../../../utils/supportedFlagIconList';
import PlaceholderFlag from '../../../../assets/svgs/PlaceholderFlag';
import { FlagIcon } from 'react-flag-kit';
import CustomCheckbox from '../../../Filters/CustomCheckbox';

const CountryList = ({ allCountry, country, handleCheckboxChange, notWebsite, alerts = false }) => {
  return (
    <div className={notWebsite ? styles.countryItemMobile : styles.countryItem} key={country}>
      <div className={notWebsite ? styles.optionMobileWrapper : styles.none}>
        <label
          className={`${notWebsite ? styles.optionMobile : styles.option} ${alerts ? styles.alertOption : styles.none}`}
        >
          <CustomCheckbox
            onClickHandler={() => handleCheckboxChange(countryCodeToNameMap[country])}
            value={countryCodeToNameMap[country]}
            checked={allCountry.includes(countryCodeToNameMap[country])}
            alerts={alerts}
          />
          <span onClick={() => handleCheckboxChange(countryCodeToNameMap[country])}>
            {countryCodeToNameMap[country]}
          </span>
        </label>
      </div>
      <div className={notWebsite ? styles.flagMobile : styles.none}>
        <div className={styles.flag}>
          <span className={styles.flag}>
            {supportedCountryCodes?.includes(country) ? (
              <FlagIcon code={country} loading="lazy" size={24} />
            ) : (
              <PlaceholderFlag />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CountryList;
