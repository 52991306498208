import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Header, StyleProvider } from "ig-phoenix";
import LoginForm from "./components/LoginForm";

const queryClient = new QueryClient();

export default function Root(props) {
  return (
    <StyleProvider>
      <QueryClientProvider client={queryClient}>
        <section>
          <Header variant="h2" size="larger">
            Log in
          </Header>
          <LoginForm />
        </section>
      </QueryClientProvider>
    </StyleProvider>
  );
}
