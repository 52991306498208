import { useEffect, useRef, useState } from 'react';
import style from './AlertPopupWeb.module.css';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import CrossIconCalendarTypeIos from '../../assets/svgs/CrossIconCalendarTypeIos';
import { alertConfiguration, alertConfigurationMobile } from '../../utils/alertUtils';
import { useAlerts } from '../../hooks/useAlerts';

export default function AlertPopupWeb({ data }) {
  const [position, setPosition] = useState('bottom'); // Default to bottom
  const alertPopupRef = useRef(null);
  const { showAlertBox, setShowAlertBox, closeAlerts, handleSetAlert } = useAlerts();
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  // State to manage the input variables dynamically
  const [inputState, setInputState] = useState({
    alertPlatforms: [],
    alertRepeatitions: '',
  });

  // Function to update the state
  const updateValue = (category, option) => {
    setInputState((prevState) => {
      if (category === 'alertRepeatitions') {
        // Radio button: reset other options in the same category
        return {
          ...prevState,
          [category]: option,
        };
      }

      // Checkbox: toggle the current option
      const currentOptions = prevState[category] || [];
      const isOptionSelected = currentOptions.includes(option);

      return {
        ...prevState,
        [category]: isOptionSelected
          ? currentOptions.filter((item) => item !== option) // Remove the option
          : [...currentOptions, option], // Add the option
      };
    });
  };

  console.log(inputState, 'input state');

  const configData = isAndroid || isIos ? alertConfigurationMobile : alertConfiguration;

  const handleSetAlertData = () => {
    handleSetAlert(data, inputState);
  };

  useEffect(() => {
    const handlePosition = () => {
      const alertPopup = alertPopupRef.current;
      const triggerElement = document.getElementById('alertPopup');
      const triggerRect = triggerElement?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - triggerRect.bottom;
      const spaceAbove = triggerRect.top - 300;

      if (spaceBelow < alertPopup.offsetHeight && spaceAbove > alertPopup.offsetHeight) {
        setPosition('top');
      } else {
        setPosition('bottom');
      }
    };

    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const alertPopUpBoxElement = clickedElement.closest('.alertPopup');
      if (
        showAlertBox &&
        alertPopupRef.current &&
        !alertPopupRef.current.contains(event.target) &&
        !alertPopUpBoxElement
      ) {
        setShowAlertBox(false);
      }
    };

    handlePosition();
    window.addEventListener('resize', handlePosition);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handlePosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAlertBox]);

  return (
    <>
      {notWebsite && <div className={style.modal}></div>}
      <div
        className={`${!notWebsite ? style.alertContainer : style.alertMobileContainer} ${
          !notWebsite ? (position === 'top' ? style.topPosition : style.bottomPosition) : style.none
        }`}
        id="alertPopup"
        ref={alertPopupRef}
      >
        {notWebsite && (
          <div className={style.alertsMobileHeadingWrapper}>
            <div className={style.alertsMobileHeading}>
              <button type="button">Reset</button>
              <span>Set alert</span>
            </div>
            <div onClick={closeAlerts}>
              <CrossIconCalendarTypeIos />
            </div>
          </div>
        )}

        {Object.entries(configData).map(([category, data]) => (
          <div key={category} className={notWebsite ? style.alertMeViaMobile : style.none}>
            <p className={notWebsite ? style.alertHeadMobile : style.alertHead}>{data.text}</p>
            <div className={notWebsite ? style.gapBetweenMobile : style.gapBetween}>
              {data.options.map((option) => (
                <div
                  key={option[0]}
                  className={notWebsite ? style.alignCheckBoxLineMobile : style.alignCheckBoxLine}
                >
                  {/* {notWebsite ? (
                    <CustomCheckbox
                      onClickHandler={() => updateValue(category, option[0])}
                      value={option}
                      checked={inputState[category]?.[option[0]]}
                    />
                  ) : ( */}
                  <input
                    type={category === 'alertRepeatitions' ? 'radio' : 'checkbox'}
                    id={option[0]}
                    name={category}
                    checked={inputState[category]?.[option[0]]}
                    onChange={() => updateValue(category, option[0])}
                  />
                  {/* )} */}
                  <label htmlFor={option[0]} className={style.labelText}>
                    <span>{option[0]}</span>
                    {!notWebsite && option[1] && (
                      <p className={style.recurringSpanBottom}>{option[1]}</p>
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className={notWebsite ? style.buttonDivMobile : style.buttonDiv}>
          <div
            role="button"
            className={notWebsite ? style.setAlertButtonMobile : style.setAlertButton}
            onClick={handleSetAlertData}
          >
            Set Alert
          </div>
        </div>
      </div>
    </>
  );
}
