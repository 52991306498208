import { ENVIRONMENTS } from '../components/AppProps'

const ENDPOINTS = {
  prod: 'https://api.ig.com/findergateway/v1/instruments/defaultTradeable',
  test: 'https://net-api.ig.com/findergateway/v1/instruments/defaultTradeable',
  uat: 'https://web-api.ig.com/findergateway/v1/instruments/defaultTradeable',
  demo: 'https://demo-api.ig.com/findergateway/v1/instruments/defaultTradeable',
  local: 'https://net-api.ig.com/findergateway/v1/instruments/defaultTradeable'
}
export default async function fetchEpic (env: keyof typeof ENVIRONMENTS, cst: string, accountId: string, isin: string, country: string): Promise<any> {
  return await fetch(`${ENDPOINTS[env]}?country=${country}&isin=${isin}`, {
    headers: {
      cst,
      'IG-ACCOUNT-ID': accountId
    }
  })
    .then(res => {
      if (!res.ok) throw new Error('Fetch error')
      return res.json()
    }).then(res => {
      if (res.instruments.length < 1) {
        return 'Unavailable'
      }
      return res.instruments[0].epic
    })
    .catch(() => {
      throw new Error('Fetch error')
    })
}
