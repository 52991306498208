import '../styles/Size.css'
import { useTranslation } from '@ig-caa/i18n'
import { NumericInput } from 'ig-phoenix'
import { GATracking } from '../shared-types'

interface SizeProps {
  minContracts: number
  updateSize: (value?: string) => void
  updateOpening: (value?: string) => void
  updateClosing: (value?: string) => void
  valueSize?: string
  valueOpening?: string
  valueClosing?: string
  decimalPlacesFactor?: number
  initialValue?: number
  isShares: boolean
  scalingFactor: number
  epic: string
  instrumentName: string
  gaTracking: GATracking
}

export default function Size (props: SizeProps): JSX.Element {
  const { t } = useTranslation()
  const {
    minContracts, updateSize, updateOpening, updateClosing, valueSize, valueOpening, valueClosing,
    decimalPlacesFactor, initialValue, isShares, scalingFactor, epic, instrumentName, gaTracking
  } = props

  const levelsStep = 1 / scalingFactor
  const levelsInitialValue = initialValue ? +initialValue.toFixed((levelsStep.toString().split('.')[1] || '').length) : 0
  const marketMovedBy = (valueClosing && valueOpening) ? (+valueClosing - +valueOpening).toFixed(decimalPlacesFactor) : null
  const marketMovedByPercentage = (valueClosing && valueOpening) ? ((+valueClosing - +valueOpening) / +valueOpening * 100).toFixed(2) : null
  return (
    <div className='size-and-levels'>
      <div className='same-row upper-padding'>
        <label htmlFor='size' className='size-header text'>{t('size')}</label>
        <div className='left-align'>
          <NumericInput
            id='size'
            onUpdate={(value) => {
              if (!valueSize) { gaTracking(epic, instrumentName, 'Size', 'Interacting P&L calculator tool') }
              updateSize(value)
            }}
            max={9999999}
            step={isShares ? ((valueSize && +valueSize >= 1000) ? 100 : 10) : 1}
            decimalPlaces={isShares ? 0 : 2}
            min={minContracts}
            value={valueSize?.toString()}
            hasError={valueSize != undefined && +valueSize < minContracts}>
          </NumericInput>
        </div>
      </div>
      <div className='sub-text'>{isShares ? t('min-shares') : t('min-contracts')}: {minContracts}</div>
      <div className='same-row upper-padding'>
        <label htmlFor='openingPriceLevel' className='opening-price-header text'>{t('opening-price-level')}</label>
        <div className='left-align'>
          <NumericInput
            id='openingPriceLevel'
            onUpdate={(value) => {
              if (!valueOpening) { gaTracking(epic, instrumentName, 'Opening price level', 'Interacting P&L calculator tool') }
              updateOpening(value)
            }}
            max={9999999}
            step={levelsStep}
            decimalPlaces={decimalPlacesFactor}
            initialValue={levelsInitialValue}
            value={valueOpening?.toString()}>
          </NumericInput>
        </div>
      </div>
      <div className='same-row upper-padding'>
        <label htmlFor='closingPriceLevel' className='closing-price-header text'>{t('closing-price-level')}</label>
        <div className='left-align'>
          <NumericInput
            id='closingPriceLevel'
            onUpdate={(value) => {
              if (!valueClosing) { gaTracking(epic, instrumentName, 'Closing price level', 'Interacting P&L calculator tool') }
              updateClosing(value)
            }}
            max={9999999}
            step={levelsStep}
            decimalPlaces={decimalPlacesFactor}
            initialValue={levelsInitialValue}
            value={valueClosing?.toString()}>
          </NumericInput>
        </div>
      </div>
      {valueClosing && +valueClosing != 0 && valueOpening && +valueOpening != 0
        ? <div className='sub-text market-moved' data-testid='market-moved-value-and-percentage'>
            {`${t('market-moved')}: ${marketMovedBy} (${marketMovedByPercentage}%)`}
          </div>
        : null}
    </div>
  )
};
