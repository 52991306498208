import { conversionText } from '../dataConversion/ja';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import style from '../styles/EventsBasedOnImportance.module.css';
import {
  getCountryCode,
  getCountryName,
  holidayDifferentLocales,
  importanceMapping,
} from '../utils/util';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const getImportanceCount = (
  econData: {},
  dateFilterOption: string,
  activeTab: string,
  allCountry: string[],
  searchText: string,
) => {
  const { locale } = useEconCalendarParams();
  const importanceCount = {
    high: 0,
    medium: 0,
    low: 0,
  };

  const selectedFilterData = econData[dateFilterOption];

  selectedFilterData &&
    Object.keys(selectedFilterData).forEach((data) => {
      const dateData = selectedFilterData[data];
      dateData.length &&
        dateData?.forEach((value) => {
          let countryCode;
          if (activeTab === 'dividends-reports') {
            countryCode = value?.symbol?.split(':')[1];
          } else {
            value.country = getCountryName(value, 'country');
            countryCode = getCountryCode(value.country);
            countryCode = countryCode && countryCode.length > 0 && countryCode[0][0];
          }
          if (
            value.importance >= 0 &&
            !holidayDifferentLocales.includes(value.category) &&
            countryCode.length &&
            allCountry &&
            allCountry.length > 0 &&
            allCountry?.includes(countryCode)
          ) {
            importanceCount[importanceMapping[value.importance]] =
              importanceCount[importanceMapping[value.importance]] + 1;
          }
        });
    });
  return importanceCount;
};

const EventsBasedOnImportance = ({ activeDate, dateFilterOption }) => {
  const { econData, isDataLoading, activeTab, allCountry, searchText } = useEconCalendar();
  const importanceCount = getImportanceCount(
    econData,
    dateFilterOption,
    activeTab,
    allCountry,
    searchText,
  );
  const { baseColorSkeleton, highlightColorSkeleton } = useEconCalendarParams();

  return (
    <div>
      {!isDataLoading || (econData && Object.values(econData).length > 0) ? (
        <div className={`${style.eventNumberOnImportance} ${activeDate ? style.activeDate : ''}`}>
          <span className={`${style.highImportance} ${style.fontStyling}`}>
            {importanceCount['high']}
          </span>
          <span className={`${style.mediumImportance} ${style.fontStyling}`}>
            {importanceCount['medium']}
          </span>
          <span className={`${style.lowImportance} ${style.fontStyling}`}>
            {importanceCount['low']}
          </span>
        </div>
      ) : (
        <SkeletonTheme baseColor={baseColorSkeleton} highlightColor={highlightColorSkeleton}>
          <div>
            <Skeleton height={10} />
          </div>
        </SkeletonTheme>
      )}
    </div>
  );
};

export default EventsBasedOnImportance;
