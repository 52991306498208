import Info from '../../../../assets/svgs/Info';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { alertSettingsConfigMobile } from '../../../../utils/alertUtils';
import style from './AlertSettings.module.css';
import { useAlerts } from '../../../../hooks/useAlerts';

const AlertSettingOptions = ({ settingsKeys, updateShowInfo, data = {} }) => {
  const {
    userAlertPreference,
    updateUserAlertPreferences,
    showDefaultSettingsMobile,
    alertsSettings,
  } = useAlerts();
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  const preferenceData =
    Object.keys(data).length > 0
      ? {
          alertMe: [],
          // @ts-ignore
          alertPlatforms: data?.notificationChannel?.map((item) => {
            return item === 'PUSH' ? 'Push notification' : 'Web platform';
          }),
          // @ts-ignore
          alertRepeatitions: data?.reoccurring ? 'Every time' : 'Only once',
        }
      : userAlertPreference;

  const { alertMe, alertPlatforms, alertRepeatitions } = preferenceData;

  return (
    <>
      {settingsKeys?.map((item, index) => {
        return (
          <div className={notWebsite ? style.indAlertBoxMobile : style.indAlertBox} key={item}>
            <p className={notWebsite ? style.alertTextMobile : style.alertText}>
              {alertSettingsConfigMobile[item].text}
            </p>
            {index === 0 && notWebsite && showDefaultSettingsMobile && (
              <button className={style.infoMobile} onClick={() => updateShowInfo(true)}>
                <Info width="16" height="16" />
              </button>
            )}
            <div className={notWebsite ? style.alertItemsMobile : style.alertItems}>
              {alertsSettings &&
                alertSettingsConfigMobile[item]?.options?.map((option) => {
                  let checked = false;

                  if (item === 'alertMe') {
                    checked = alertMe?.includes(option);
                  } else if (item === 'alertPlatforms') {
                    checked = alertPlatforms?.includes(option);
                  } else if (item === 'alertRepeatitions') {
                    checked =
                      (option === 'Every time' && alertRepeatitions === 'Every time') ||
                      (option === 'Only once' && alertRepeatitions === 'Only once');
                  }
                  return (
                    <div
                      key={option}
                      className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${isAndroid && style.androidDirection}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type={item !== 'alertRepeatitions' ? 'checkbox' : 'radio'}
                        id={option}
                        name={item !== 'alertRepeatitions' ? option : item}
                        value={option}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => updateUserAlertPreferences(option)}
                        checked={checked}
                      />
                      <label
                        htmlFor={option}
                        className={notWebsite ? style.labelTextMobile : style.labelText}
                      >
                        {option}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AlertSettingOptions;
