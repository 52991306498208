import { encryptPassword } from "./encryption";

// TODO - urls 
const BASE_PATH = 'https://net.ig.com'



const verifyRes = (res: Response) => {
  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
}

export async function postLoginForm(credentials: Credentials) {
  const { encryptionKey, timeStamp } = await getEncryptionKey();

  const encryptedPassword = await encryptPassword(credentials.password, encryptionKey, timeStamp);

  return fetch(`${BASE_PATH}/uk/validate/api/session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: credentials.username,
      password: encryptedPassword,
      enc: true,
      advertisingId: null,
    })
  })
  .then(verifyRes)
  .then((res) => {
    return res.json()
  }).then((json: LoginRes) => {
    if (!json.success) {
      throw new Error((json as FailureLoginResponse).reasons.join('\n'));
    }

    return json as SuccessLoginResponse;
  }) 
}

export async function getEncryptionKey() {
  return fetch(`${BASE_PATH}/uk/validate/api/encryptionkey`)
  .then(verifyRes)
  .then((res) => res.json())
}