import { useEffect, useRef, useState } from 'react';
import style from './ManageAlertsPopup.module.css';
import AlertSettings from './AlertSettings/AlertSettings';
import BulkAlertSettings from './BulkAlerts/BulkAlertSettings';
import { useEconCalendarParams } from '../../../hooks/use-econ-calendar-params';
import BackArrowiOS from '../../../assets/svgs/BackArrowiOS';
import { useAlerts } from '../../../hooks/useAlerts';
import AlertSettingsSkeleton from './AlertSettingsSkeleton';
import BackArrow from '../../../assets/svgs/BackArrow';

export default function ManageAlertsPopup() {
  const manageAlertPopupRef = useRef(null);
  const {
    showManageAlerts,
    updateShowManageAlerts,
    handleDeleteAllAlerts,
    fetchAlertsSettings,
    fetchAlertsData,
    setAlertsData,
    activeAlertTab,
    setActiveAlertTab,
    selectedAlertType,
    handlePostAlertSettings,
    isAlertsLoading,
    showBulkAlertsDrawer,
    tabAlertData: alertsData,
  } = useAlerts();
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  const handleTabClick = (tabName: string) => {
    setAlertsData([]);
    setActiveAlertTab(tabName);
  };

  useEffect(() => {
    if (!showBulkAlertsDrawer) {
      if (activeAlertTab === 'alertSettings') fetchAlertsSettings();
      fetchAlertsData(activeAlertTab, selectedAlertType);
    }
  }, [activeAlertTab, selectedAlertType, showBulkAlertsDrawer]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const manageAlertsElement = clickedElement.closest('.manageAlertsPopup'); // Select closest ancestor with class
      const manageAlertButton = clickedElement.closest('#alertSettingsEcon');
      if (
        showManageAlerts &&
        manageAlertPopupRef.current &&
        !manageAlertPopupRef.current.contains(event.target) &&
        !manageAlertsElement &&
        !manageAlertButton
      ) {
        closeManageAlertsPopUp();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.addEventListener('mousedown', handleClickOutside);
  }, []);

  const closeManageAlertsPopUp = () => {
    updateShowManageAlerts(false);
    setAlertsData([]);
  };

  return (
    <div
      className={`manageAlertsPopup ${notWebsite ? style.manageAlertContainerMobile : style.manageAlertContainer}`}
      id="manageAlertPopup"
      ref={manageAlertPopupRef}
    >
      {notWebsite && (
        <div className={style.mobileHeader}>
          <button onClick={() => updateShowManageAlerts(false)}>
            {isIos ? <BackArrowiOS /> : <BackArrow />}
          </button>
          <span> Manage Alerts </span>
        </div>
      )}
      <div className={notWebsite ? style.mobileTabs : style.tabs}>
        <button
          className={`${notWebsite ? style.mobileTab : style.tab} ${activeAlertTab === 'alertSettings' ? style.activeTab : ''}`}
          onClick={() => activeAlertTab !== 'alertSettings' && handleTabClick('alertSettings')}
        >
          Alert Settings
        </button>
        <button
          className={`${notWebsite ? style.mobileTab : style.tab} ${activeAlertTab === 'bulkAlerts' ? style.activeTab : ''}`}
          onClick={() => activeAlertTab !== 'bulkAlerts' && handleTabClick('bulkAlerts')}
        >
          Bulk Alerts
        </button>
      </div>
      <div className={notWebsite ? style.tabContentMobile : style.tabContent}>
        {!isAlertsLoading ? (
          <div>
            {activeAlertTab === 'alertSettings' && <AlertSettings />}
            {activeAlertTab === 'bulkAlerts' && (
              <BulkAlertSettings activeAlertTab={activeAlertTab} />
            )}
          </div>
        ) : (
          <div className={style.loading}>
            <AlertSettingsSkeleton />
          </div>
        )}
      </div>

      {!notWebsite && !isAlertsLoading && (
        <div className={style.lowerNavs}>
          <span
            className={`${style.deleteAll} ${alertsData && alertsData?.length > 0 ? style.none : style.deleteAllDisabled}`}
            onClick={() => handleDeleteAllAlerts(selectedAlertType)}
          >
            Delete all events
          </span>
          <button
            className={style.done}
            onClick={
              activeAlertTab === 'alertSettings' ? handlePostAlertSettings : closeManageAlertsPopUp
            }
          >
            {activeAlertTab === 'alertSettings' ? 'Done' : 'Close'}
          </button>
        </div>
      )}
    </div>
  );
}
