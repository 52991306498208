import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import PnLCalculator from './PnLCalculator'
import { initTranslation, I18nextProvider } from '@ig-caa/i18n'
import '../styles/App.css'
import { SessionData, Theme, Environment, GATracking } from '../shared-types'
import { StyleProvider } from 'ig-phoenix'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { SessionProvider } from '../hooks/useSession'
import { LightstreamerProvider } from '@ig-wpx/lightstreamer'
import { resolveDomain } from '../utils/resolveDomain'
import { firstNumberInString } from '../utils/formatNumber'

interface AppProps {
  apiHost: string
  cst: string
  sessionData: SessionData
  theme: Theme
  xst: string
  children?: ReactNode
  env: Environment
  epic: string
  currencyName: string
  currencySymbol: string
  valueOfOnePip: number | string
  onePipMeans: number | string
  minContracts: number | string
  decimalPlacesFactor?: number
  isShares: boolean
  scalingFactor: number
  instrumentName: string
  gaTracking?: GATracking
}

interface AppInnerProps extends AppProps {
  gaTracking: GATracking
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

/* istanbul ignore next */
const DEFAULT_GA_TRACKING: GATracking = (
  epic,
  instrumentName,
  interactionField,
  interactionType) => {
  console.log('ga-tracked:', epic, instrumentName, interactionField, interactionType)
}

export function App ({ gaTracking = DEFAULT_GA_TRACKING, ...props }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppInner {...props} gaTracking={gaTracking} />
    </QueryClientProvider>
  )
}

function AppInner (props: AppInnerProps) {
  const { theme } = props

  const supportedLanguages = ['en-GB']
  const loadTranslationsFn = async () =>
    await initTranslation(
      props.sessionData.clientLocale,
      supportedLanguages,
      /* istanbul ignore next */
      async (locale: string) => await import(`../locales/${locale}.json`)
    )

  const { isLoading, isError, error, data: i18n } = useQuery(['profitLossCalcLoadTranslations', props.sessionData.clientLocale], loadTranslationsFn)

  if (isLoading) return <>Loading...</>

  if (isError) return <>An error has occurred: {(error as Error).message}</>

  return (
    <I18nextProvider i18n={i18n}>
      <div className={`app-caa-pnl-calc ${props.theme}`} data-testid='app'>
        <StyleProvider data-testid='style-provider' colorScheme={theme}>
          {/* Note: add withFonts={true} in the above when developing, but do not release, otherwise WTP will load it twice */}
          <SessionProvider {...props}>
            <LightstreamerProvider
              origin={`https://${resolveDomain(props.apiHost)}wtp.ig.com`}
              client={{
                currentAccountId: props.sessionData.currentAccountId,
                cst: props.cst,
                env: props.env
              }}
            >
              <PnLCalculator
                clientLocale={props.sessionData.clientLocale}
                webSiteId={props.sessionData.webSiteId}
                env={props.env}
                currencyName={props.currencyName}
                currencySymbol={props.currencySymbol}
                valueOfOnePip={firstNumberInString(props.valueOfOnePip)}
                onePipMeans={firstNumberInString(props.onePipMeans)}
                minContracts={+firstNumberInString(props.minContracts).toFixed(2)}
                decimalPlacesFactor={props.decimalPlacesFactor ?? 0}
                epic={props.epic}
                isShares={props.isShares}
                scalingFactor={props.scalingFactor}
                instrumentName={props.instrumentName}
                gaTracking={props.gaTracking}
              />
            </LightstreamerProvider>
          </SessionProvider>
        </StyleProvider>
      </div>
    </I18nextProvider>
  )
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App
})

export const { bootstrap, mount, unmount, update } = lifecycles
export default lifecycles
