import Reports from './Reports';
import EarningsReport from './EarningsReport';
import { useEconCalendar } from '../../hooks/useEconCalendar';

export default function TabRowDataIndividiualRow({
  activeTab,
  tabKey,
  index,
  singleDataEntry,
  marketTrend,
  headingsNameCheck,
  dataAccessProperty,
  widthStyling,
  showAlertBox,
}) {
  const { calendarWidth } = useEconCalendar();
  const isWorld = ['events', 'earnings-reports'].includes(activeTab)
    ? singleDataEntry?.country?.toUpperCase() === 'WORLD'
    : '';

  return (
    <div
      className={`${
        (tabKey === 'country' || tabKey === 'importance') &&
        ['events', 'earnings-reports'].includes(activeTab) &&
        calendarWidth >= 768
          ? tabKey + widthStyling
          : headingsNameCheck[tabKey] || tabKey
      } font`}
      key={index}
    >
      {activeTab !== 'earnings-reports' ? (
        <Reports
          activeTab={activeTab}
          tabKey={tabKey}
          singleDataEntry={singleDataEntry}
          dataAccessProperty={dataAccessProperty}
          isWorld={isWorld}
          showAlertBox={showAlertBox}
        />
      ) : (
        <EarningsReport
          tabKey={tabKey}
          singleDataEntry={singleDataEntry}
          marketTrend={marketTrend}
          isWorld={isWorld}
        />
      )}
    </div>
  );
}
