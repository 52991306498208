export const resolveDomain = (apiHost = '') => {
  /* istanbul ignore next */
  if (apiHost.indexOf('net') === 8) {
    return 'net-'
    /* istanbul ignore next */
  } else if (apiHost.indexOf('web') === 8) {
    return 'web-'
  } else if (apiHost.indexOf('demo') === 8) {
    return 'demo-'
  }
  return ''
}
