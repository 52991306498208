export const getEconAlertsSettings = async (cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/settings`, {
    headers: {
      'Content-Type': 'application/json',
      CST: cst,
    },
  });
};

export const postAlertSettingsData = async (payload, cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/settings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      CST: cst,
    },
    body: JSON.stringify(payload),
  });
};

export const getEconAlertsData = async (cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/macro`, {
    method: 'GET',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      CST: cst,
    },
  });
};

export const postEconAlertsData = async (payload, cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/macro`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      CST: cst,
    },
    body: JSON.stringify(payload),
  });
};

export const updateEconAlertsData = async (payload, cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/macro/alertId/${payload.alertId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      CST: cst,
    },
    body: JSON.stringify(payload),
  });
};

export const deleteEconAlertsData = async (alertId, cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/macro/alertId/${alertId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      CST: cst,
    },
  });
};

export const deleteAllEconAlertsData = async (cst, alertsApiUrl) => {
  return await fetch(`${alertsApiUrl}/econ/macro`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      CST: cst,
    },
  });
};

export const deleteAllEconBulkAlertsData = async (cst, alertsApiUrl, selectedBulkAlertTab) => {
  return await fetch(
    `${alertsApiUrl}/econ/bulk/${selectedBulkAlertTab === 'macroeconomicEvents' ? 'macro' : 'earning'}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        CST: cst,
      },
    },
  );
};

export const getEconBulkAlertsData = async (cst, alertsApiUrl, selectedBulkAlertTab) => {
  return await fetch(
    `${alertsApiUrl}/econ/bulk/${selectedBulkAlertTab === 'macroeconomicEvents' ? 'macro' : 'earning'}`,
    {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        CST: cst,
      },
    },
  );
};

export const postEconBulkAlertsData = async (payload, cst, alertsApiUrl, selectedBulkAlertTab) => {
  return await fetch(
    `${alertsApiUrl}/econ/bulk/${selectedBulkAlertTab === 'macroeconomicEvents' ? 'macro' : 'earning'}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        CST: cst,
      },
      body: JSON.stringify(payload),
    },
  );
};

export const deleteEconBulkAlertsData = async (
  payload,
  cst,
  alertsApiUrl,
  selectedBulkAlertTab,
) => {
  return await fetch(
    `${alertsApiUrl}/econ/bulk/${selectedBulkAlertTab === 'macroeconomicEvents' ? 'macro' : 'earning'}/alertId/${payload.alertId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        CST: cst,
      },
      body: JSON.stringify(payload),
    },
  );
};
