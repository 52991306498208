import { CloseSign } from '@ig-caa/media'
import styled from 'styled-components'
import { FilterByColumnStateObjType } from './FilterByColumnStateObj'
import { Dispatch, SetStateAction } from 'react'

interface FilteredColumnCardProps {
  filter: FilterByColumnStateObjType
  setApiQuery: Dispatch<SetStateAction<string>>
  setData: Dispatch<SetStateAction<any[] | null>>
  setPage: Dispatch<SetStateAction<number>>
  apiQuery: string
  setActiveFilterCount: Dispatch<SetStateAction<number>>
  trackingProps: Object
}

export default function FilteredColumnCards (props: FilteredColumnCardProps) {
  const { filter, setApiQuery, apiQuery, setData, setPage, setActiveFilterCount, trackingProps } = props

  const disableFilter = (keyName: string, categoryName: string, categoryValue: string) => {
    const filterCardToBeClosed = filter[keyName].filters.find((a: { categoryName: string }) => categoryName === a.categoryName)
    /* istanbul ignore next */
    if (!filterCardToBeClosed) return
    filterCardToBeClosed.checked = false
    setData([])
    setPage(1)
    setApiQuery(apiQuery.replace(`&${keyName}=${categoryValue}`, ''))
    setActiveFilterCount((prevState) => prevState - 1)
    window.dataLayer.push({ ...trackingProps, interaction_type: 'filter_removal', filter_type: filter[keyName].displayName, filter_value: filterCardToBeClosed.categoryName })
  }

  return (
    <FilterContainer>
      {Object.keys(filter).map(key => {
        const category = filter[key]
        return category.filters.filter((filter: { checked: boolean }) => filter.checked)
          .map((filter: { categoryName: string, categoryValue: string }) => (
            <FilterCard data-testid={`${key}-${filter.categoryName}-card`} key={`${key}-${filter.categoryName}`}>
              <FilterCardText>
                {`${category.displayName}: ${filter.categoryName}`}
              </FilterCardText>
              <div
                data-testid={`${key}-${filter.categoryName}-card-close`}
                onClick={() => { disableFilter(key, filter.categoryName, filter.categoryValue) }} style={{ cursor: 'pointer' }}>
                <CloseSign />
              </div>
            </FilterCard>
          ))
      })}
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
`

const FilterCard = styled.div`
  height: 16px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: no-wrap;
  gap: 8px;
  margin: 0px 8px 4px 0px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.color.separator.secondary.background.value};
  box-sizing: content-box;
  background: ${({ theme }) => theme.color.checkbox.disabled.background.value};
`

const FilterCardText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
`
