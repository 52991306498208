import '../styles/Direction.css'
import { useTranslation } from '@ig-caa/i18n'
import { Directions } from '../shared-types'
import PriceTick from './PriceTick'

interface DirectionProps {
  setSelectedDirection: (value: Directions) => void
  selectedDirection: Directions
  decimalPlacesFactor?: number
  bid?: number
  offer?: number
}

export default function Direction (props: DirectionProps): JSX.Element {
  const { t } = useTranslation()
  const { setSelectedDirection, selectedDirection, decimalPlacesFactor, bid, offer } = props

  return (
    <div className='direction-buttons'>
      <div className='direction-label'>{t('direction')}</div>
      <div className='button-spacing'>
        <div className='button-container' data-testid='button-container'>
          <PriceTick
            tickPrice={bid}
            direction='sell'
            selectedDirection={selectedDirection}
            handleDirectionClick={() => {
              setSelectedDirection('sell')
            }}
            decimalPlacesFactor={decimalPlacesFactor}
            className='latest-price' />
          <PriceTick
            tickPrice={offer}
            direction='buy'
            selectedDirection={selectedDirection}
            handleDirectionClick={() => {
              setSelectedDirection('buy')
            }}
            decimalPlacesFactor={decimalPlacesFactor}
            className='latest-price' />
        </div>
      </div>
    </div>
  )
}
