import useScreenDimensions from '../../hooks/useScreenDimension';
import {
  convertValueOnLocale,
  formatPaymentDate,
  formatPaymentDateDividendsMobile,
  formatToTwoDecimalPlaces,
  getAccountTime,
  getCountryName,
  getFormattedTime,
  getLondonTime,
  getStartPrice,
  getTimeDiff,
  getValueWithCurrency,
  holidayDifferentLocales,
  importanceMapping,
  searchCountryCode,
} from '../../utils/util';
import Badge from '../Global/Badge';
import { countryCodeToNameMap } from '../../utils/countryCodeNameMapping';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { FlagIconCode } from '../../types/flagIconCode';
import { FlagIcon } from 'react-flag-kit';
import ToEnableAlert from '../../assets/svgs/ToEnableAlert';
import ArrowExpandIcon from '../../assets/svgs/ArrowExpandIcon';
import EventCollapse from '../../assets/svgs/EventCollapse';
import { useSessionData } from '../../hooks/use-session-data';
import WorldFlag from '../../assets/svgs/WorldFlag';
import { supportedCountryCodes } from '../../utils/supportedFlagIconList';
import PlaceholderFlag from '../../assets/svgs/PlaceholderFlag';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import { getLocalTime } from '../../utils/econ-utils';
import AlertIconWeb from '../IconComponents/AlertIconWeb';
import AlertPopupWeb from '../Alerts/AlertPopupWeb';
import { getConversionText } from '../../utils/tabsDataAccess-utils';
import { useAlerts } from '../../hooks/useAlerts';

const Reports = ({
  activeTab,
  tabKey,
  singleDataEntry,
  dataAccessProperty,
  isWorld,
  showAlertBox,
}) => {
  const { t } = useTranslation();
  const { locale, isIos, isAndroid } = useEconCalendarParams();
  const isMobile = useScreenDimensions();
  const country = getCountryName(singleDataEntry, tabKey);
  const countryName =
    activeTab === 'dividends-reports'
      ? countryCodeToNameMap[singleDataEntry['symbol']?.split(':')[1]]
      : country;

  const dataValue = getConversionText(
    singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] || singleDataEntry[tabKey],
    locale,
    tabKey,
    singleDataEntry,
  );
  const flagCode =
    activeTab === 'dividends-reports'
      ? singleDataEntry['symbol']?.split(':')[1]
      : (searchCountryCode(country) as FlagIconCode);

  const isHoliday = holidayDifferentLocales?.includes(singleDataEntry?.category);
  const { selectedChartSection, isExpanded, calendarWidth } = useEconCalendar();
  const { timezoneOffset } = useSessionData();
  const { isAlertsEnabled } = useAlerts();

  const twoDecimalPlaceValue =
    ['actual', 'previous', 'forecast', 'amount', 'startPrice', 'value'].includes(tabKey) &&
    !isHoliday
      ? singleDataEntry[tabKey] !== ''
        ? ['amount', 'value'].includes(tabKey)
          ? getValueWithCurrency(
              formatToTwoDecimalPlaces(singleDataEntry[tabKey]),
              locale,
              singleDataEntry['currency'],
              false,
            )
          : tabKey === 'startPrice'
            ? getStartPrice(singleDataEntry, tabKey, locale)
            : singleDataEntry['currency']
              ? getValueWithCurrency(
                  formatToTwoDecimalPlaces(
                    singleDataEntry[tabKey]?.replace(singleDataEntry['currency'], ''),
                  ),
                  locale,
                  singleDataEntry['currency'],
                  true,
                )
              : convertValueOnLocale(formatToTwoDecimalPlaces(singleDataEntry[tabKey]), locale)
        : '-'
      : '';

  const splitsValue =
    activeTab === 'stock-splits' && tabKey === 'ratio'
      ? singleDataEntry['ratio']?.includes(':')
        ? `${convertValueOnLocale(formatToTwoDecimalPlaces(singleDataEntry['ratio'])?.split(':')?.[0], locale)}:${convertValueOnLocale(formatToTwoDecimalPlaces(singleDataEntry['ratio'])?.split(':')?.[1], locale)}`
        : singleDataEntry['ratio']
      : '';

  const {
    getTooltipProps: getTooltipProps,
    setTooltipRef: setTooltipRef,
    setTriggerRef: setTriggerRef,
    getArrowProps: getArrowProps,
    visible: visible,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps1,
    setTooltipRef: setTooltipRef1,
    setTriggerRef: setTriggerRef1,
    getArrowProps: getArrowProps1,
    visible: visible1,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps2,
    setTooltipRef: setTooltipRef2,
    setTriggerRef: setTriggerRef2,
    getArrowProps: getArrowProps2,
    visible: visible2,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const {
    getTooltipProps: getTooltipProps3,
    setTooltipRef: setTooltipRef3,
    setTriggerRef: setTriggerRef3,
    getArrowProps: getArrowProps3,
    visible: visible3,
  } = usePopperTooltip({ trigger: 'hover', placement: 'top' });

  const eventTime =
    tabKey === 'time' &&
    getFormattedTime(
      singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] || singleDataEntry[tabKey],
      timezoneOffset,
    );

  return (
    <>
      {tabKey === 'expand' ? (
        !isHoliday &&
        (isExpanded && selectedChartSection.id === singleDataEntry.id ? (
          <EventCollapse />
        ) : (
          <ArrowExpandIcon />
        ))
      ) : tabKey === 'alert' && isAlertsEnabled ? (
        <div className="alertWeb">
          <AlertIconWeb />
          {showAlertBox && !(isIos || isAndroid) && <AlertPopupWeb data={singleDataEntry} />}
        </div>
      ) : tabKey === 'time' && activeTab === 'events' && dataValue ? (
        <span className={isHoliday ? 'hide' : 'show'}>
          <span className="eventTime"> {eventTime}</span>
          {!isMobile && (
            <span className="timeText">
              {timezoneOffset !== null
                ? getTimeDiff(
                    getAccountTime(getLondonTime(), timezoneOffset),
                    singleDataEntry['date'],
                    timezoneOffset,
                  )
                : getTimeDiff(getLocalTime(), singleDataEntry['date'], null)}
            </span>
          )}
        </span>
      ) : tabKey === 'importance' && importanceMapping[singleDataEntry[tabKey]] ? (
        <Badge text={importanceMapping[singleDataEntry[tabKey]]} holiday={isHoliday} />
      ) : tabKey === 'country' ? (
        <div className="countryDetails">
          <>
            <div className="flag" ref={setTriggerRef1}>
              {isWorld ? (
                <WorldFlag />
              ) : supportedCountryCodes?.includes(flagCode) ? (
                <FlagIcon code={flagCode} loading="lazy" />
              ) : (
                <PlaceholderFlag />
              )}
            </div>
            {!isMobile && visible1 && (
              <div
                ref={setTooltipRef1}
                {...getTooltipProps1({ className: 'tooltip-container tooltip' })}
              >
                {t(countryName)}
                <div {...getArrowProps1({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </>
          {!isMobile && (activeTab === 'events' ? calendarWidth > 900 : true) && (
            <>
              <div className={`countryName ${isHoliday ? 'holiday' : ''}`} ref={setTriggerRef2}>
                {t(countryName)}
              </div>

              {!isMobile && visible2 && (
                <div
                  ref={setTooltipRef2}
                  {...getTooltipProps2({ className: 'tooltip-container tooltip' })}
                >
                  {t(countryName)}
                  <div {...getArrowProps2({ className: 'tooltip-arrow' })} />
                </div>
              )}
            </>
          )}
        </div>
      ) : tabKey === 'company' && activeTab !== 'events' ? (
        <div className="companyDetails">
          <>
            <div className={`name ${isMobile ? 'calendar-medium' : 'none'}`} ref={setTriggerRef3}>
              {singleDataEntry.name}
            </div>
            {!isMobile && visible3 && (
              <div
                ref={setTooltipRef3}
                {...getTooltipProps3({
                  className: `tooltip-container tooltip tooltip-wrap`,
                })}
              >
                {singleDataEntry.name}
                <div {...getArrowProps3({ className: 'tooltip-arrow' })} />
              </div>
            )}
          </>
          <div className={`symbol ${isMobile ? 'calendar-regular' : 'none'}`}>
            {singleDataEntry?.symbol?.split(':')[0] || singleDataEntry?.ticker}
          </div>
        </div>
      ) : tabKey === 'event' && activeTab === 'events' ? (
        <div className="eventRowData" ref={setTriggerRef}>
          <div className={`data ${isHoliday ? 'holiday' : ''}`}>{dataValue}</div>
          {!isMobile && visible && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({
                className: `tooltip-container tooltip tooltip-wrap`,
              })}
            >
              {dataValue}
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          )}
        </div>
      ) : tabKey === 'amount' && isMobile ? (
        <>
          <span className="amount calendar-regular">{t('Amount')}: </span>
          <span className="amountValue calendar-regular">{twoDecimalPlaceValue}</span>
        </>
      ) : tabKey === 'payment day' ? (
        !isMobile && singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ? (
          formatPaymentDate(
            singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]],
            locale != 'en' ? locale : undefined,
          )
        ) : (
          <>
            <span className="payment calendar-regular">{t('Payment')}:</span>
            <span className="paymentValue calendar-regular">
              {formatPaymentDateDividendsMobile(
                singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]],
              )}
            </span>
          </>
        )
      ) : tabKey === 'exchange' && isMobile ? (
        <>
          <span className="exchangeLabel calendar-regular">{t('Exchange')}: </span>
          <span className="exchangeValue calendar-regular">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey] ||
              '-'}
          </span>
        </>
      ) : tabKey === 'startPrice' && isMobile ? (
        <>
          <span className="startPriceLabel calendar-regular">{t('Price')}:</span>
          <span className="startPriceValue calendar-regular">{twoDecimalPlaceValue}</span>
        </>
      ) : tabKey === 'value' && isMobile ? (
        <>
          <span className="valueLabel calendar-regular">{t('Value')}: </span>
          <span className="valueQuantity calendar-regular">{twoDecimalPlaceValue}</span>
        </>
      ) : tabKey === 'date' && isMobile ? (
        <>
          <span className="dateLabel calendar-regular">{t('Date')}: </span>
          <span className="dateValue calendar-regular">
            {singleDataEntry[dataAccessProperty[`${activeTab}${tabKey}`]] ||
              singleDataEntry[tabKey] ||
              '-'}
          </span>
        </>
      ) : tabKey === 'bell' && isAlertsEnabled ? (
        <>
          <ToEnableAlert />
          {showAlertBox && <AlertPopupWeb data={singleDataEntry} />}
        </>
      ) : isMobile && tabKey === 'forecast' ? (
        <>
          <span className="forecastText calendar-regular"> {t('Forecast')}: </span>
          <span className="forecastValue calendar-regular">{twoDecimalPlaceValue}</span>
        </>
      ) : isMobile && tabKey === 'actual' ? (
        <>
          <span className="actualText calendar-regular"> {t('Actual')}: </span>
          <span className="actualValue calendar-regular">{twoDecimalPlaceValue}</span>
        </>
      ) : isMobile && tabKey === 'previous' ? (
        <>
          <span className="previousText calendar-regular"> {t('Previous')}: </span>
          <span className="previousValue calendar-regular">{twoDecimalPlaceValue}</span>
        </>
      ) : isMobile && tabKey === 'ratio' ? (
        <span className="calendar-medium"> {splitsValue} </span>
      ) : twoDecimalPlaceValue !== '' ? (
        twoDecimalPlaceValue
      ) : splitsValue !== '' ? (
        splitsValue
      ) : (
        dataValue || (!isHoliday && '-')
      )}
    </>
  );
};

export default Reports;
