import { useState, useEffect, useRef } from 'react';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import { useTranslation } from 'react-i18next';
import style from '../../styles/EconCalendarTabs.module.css';
import DayPicker from '../DayPicker';
import EconCalendarFilters from '../EconCalendarFilters';
import SearchBox from '../Filters/SearchBox';
import useScreenDimensions from '../../hooks/useScreenDimension';
import MobileNavButton from './MobileNavButton';
import TabsList from './TabsList';
import FilterComponents from '../Filters/FilterComponents';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import ZoneCountryImportance from '../Filters/ZoneCountryImportance';
import MobileFilterExpandIcon from '../../assets/svgs/MobileFilterExpandIcon';
import { eventNameMappingForMobile } from '../../utils/util';
import { useAlerts } from '../../hooks/useAlerts';

const EconCalendarTabs: React.FC = () => {
  const { t } = useTranslation();
  const {
    changeActiveTab,
    activeTab,
    showDetail,
    toggleDetail,
    expandTabs,
    updateExpandTabs,
    showSearch,
    showFilters,
    updateShowSearch,
    updateShowFilters,
    isDataLoading,
    searchText,
    activeDateFilter,
    changeDateFilter,
    updateCalendarVisibility,
    extendedCountryFilter,
  } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const sectionRef = useRef<HTMLUListElement>(null);
  const [focusedButton, setFocusedButton] = useState<string | null>(null);
  const {
    isIos,
    isAndroid,
    isWebsite,
    isIpad,
    backButtonHandler,
    specifySpecificTab,
    eventIdForDetails,
  } = useEconCalendarParams();
  const { isAlertsEnabled } = useAlerts();

  const notWebsite = isAndroid || isIos;

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
        updateExpandTabs(false);
      }
    };
    document.addEventListener('keydown', handleClick);

    return () => document.removeEventListener('keydown', handleClick);
  }, []);

  const handleButtonClick = () => {
    if (showSearch) {
      updateShowSearch();
    } else {
      showFilters
        ? updateShowFilters()
        : (isAndroid || isIos) && (!showDetail || eventIdForDetails)
          ? // @ts-ignore
            backButtonHandler?.postMessage(
              JSON.stringify({
                type: 'app/click',
                identifier: 'BACK_BUTTON_CLICK',
                data: 'Back Button Click',
              }),
            )
          : toggleDetail('');
    }
  };

  const expandTabsClass = expandTabs
    ? isAndroid
      ? style.tabSectionMobileExpanded
      : style.iosTabSectionMobileExpanded
    : style.tabSectionMobile;
  const handleListClass = notWebsite
    ? expandTabsClass
    : isMobile
      ? style.tabsSectionWebsite
      : style.tabsSection;

  const showBackButton =
    (showDetail && isAndroid) ||
    (!showDetail && (isAndroid || isIos) && !isIpad) ||
    (showDetail && isIos) ||
    (showDetail && isWebsite && !notWebsite) ||
    (isMobile && isWebsite && showFilters);

  useEffect(() => {
    if (searchText === '') {
      activeDateFilter[1] === ''
        ? changeDateFilter('today', '')
        : changeDateFilter('custom date', activeDateFilter[1]);
    } else changeDateFilter('custom date', '');
  }, [searchText]);

  return (
    <div
      className={
        notWebsite || (isMobile && isWebsite)
          ? style.nav_filter_wrapper_mobile
          : style.nav_filter_wrapper
      }
    >
      <nav
        className={`${isMobile ? (isWebsite && showFilters ? style.nav_wrapper_mobile_website : style.nav_wrapper_mobile) : specifySpecificTab ? '' : style.nav_wrapper} ${
          showFilters ? style.showShadow : style.noShadow
        } ${isIos ? style.nav_wrapper_ios : style.none}`}
      >
        {showBackButton && (
          <MobileNavButton
            isMobile={isMobile}
            showFilters={showFilters}
            handleButtonClick={handleButtonClick}
            isIos={isIos}
            t={t}
          />
        )}
        {showFilters && (
          <div className={`${style.filterHeading} calendar-medium`}>{t('Filters')}</div>
        )}
        {showSearch && !isWebsite ? (
          <div
            className={`${style.searchBoxWrapper} ${
              showSearch ? style.searchBoxWrapperVisible : ''
            }`}
          >
            <SearchBox />
          </div>
        ) : (
          !showFilters &&
          !specifySpecificTab && (
            <div
              className={`${
                isMobile && expandTabs
                  ? isIos
                    ? style.nav_wrapper_mobile_expanded_ios
                    : isWebsite
                      ? style.noStyle
                      : style.nav_wrapper_mobile_expanded
                  : style.none
              } ${isMobile && isWebsite ? style.div_tabs_list : style.none}`}
            >
              <TabsList
                isMobile={notWebsite}
                expandTabs={expandTabs}
                handleListClass={handleListClass}
                sectionRef={sectionRef}
                focusedButton={focusedButton}
                setFocusedButton={setFocusedButton}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
                updateExpandTabs={updateExpandTabs}
                showDetail={showDetail}
                isDataLoading={isDataLoading}
                updateCalendarVisibility={updateCalendarVisibility}
              />
            </div>
          )
        )}
        {notWebsite && expandTabs && (
          <div className={style.modal} role="button" onClick={() => updateExpandTabs(false)}></div>
        )}
        {isIos && !isWebsite && expandTabs && (
          <span
            className={`${style.tabName} ${isMobile ? 'calendar-medium' : 'none'} ${
              isMobile ? style.mobileTabName : style.none
            }`}
          >
            {t(eventNameMappingForMobile[activeTab])}
          </span>
        )}
        {notWebsite && isIos && !specifySpecificTab && (
          <button className={style.changeBtn} onClick={() => updateExpandTabs(null)}>
            {t('change')}
          </button>
        )}
      </nav>
      {isIos && <SearchBox />}
      {isMobile && isWebsite && !showFilters && (
        <div className={style.websiteFilterSection}>
          {' '}
          <SearchBox />{' '}
          <button className={style.websiteFilterBtn} onClick={updateShowFilters}>
            {' '}
            <MobileFilterExpandIcon />{' '}
          </button>{' '}
        </div>
      )}
      {!showFilters &&
        ((!showDetail && !isIos) ||
          (notWebsite && isIos && !extendedCountryFilter) ||
          !notWebsite) && <DayPicker />}
      {(notWebsite || (isMobile && isWebsite)) && showFilters ? (
        <div className={style.filterPageMobile}>
          <FilterComponents />
        </div>
      ) : (
        !showDetail &&
        (!notWebsite || !isIos) &&
        !(isMobile && isWebsite) && <EconCalendarFilters />
      )}

      {notWebsite && isIos && !showDetail && (
        <div className={`${style.iosFilters} ${!isAlertsEnabled && style.iosFiltersHeight}`}>
          <ZoneCountryImportance />
        </div>
      )}
    </div>
  );
};

export default EconCalendarTabs;
