// @ts-nocheck
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { alertPlatformsMapping, alertSettingsConfigMobile } from '../../../../utils/alertUtils';
import style from './AlertSettings.module.css';

export const AlertSettingIndividualOptions = ({
  settingsKeys,
  alertData,
  eventAlertIndex,
  updateAlertField,
}) => {
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  const editedAlertData = alertData[eventAlertIndex];
  return (
    <>
      {settingsKeys.map((item) => {
        return (
          <div className={notWebsite ? style.indAlertBoxMobile : style.indAlertBox} key={item}>
            <p className={notWebsite ? style.alertTextMobile : style.alertText}>
              {alertSettingsConfigMobile[item].text}
            </p>
            <div className={notWebsite ? style.alertItemsMobile : style.alertItems}>
              {alertSettingsConfigMobile[item].options.map((option) => {
                const isChecked =
                  (item === 'alertRepeatitions' &&
                    editedAlertData.reoccurring === (option === 'Every time')) ||
                  (item === 'alertPlatforms' &&
                    editedAlertData.notificationChannel?.includes(alertPlatformsMapping[option]));

                return (
                  <div
                    key={option}
                    className={notWebsite ? style.selectionItemMobile : style.selectionItem}
                  >
                    <input
                      type={item === 'alertRepeatitions' ? 'radio' : 'checkbox'}
                      id={`${editedAlertData.alertId}-${option}`}
                      name={item}
                      checked={isChecked}
                      onChange={() => {
                        updateAlertField(eventAlertIndex, item, option);
                      }}
                    />
                    <label htmlFor={`${editedAlertData.alertId}-${option}`}>{option}</label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
