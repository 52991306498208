// @ts-nocheck
import styles from './BulkAlertMobileView.module.css'; // Import CSS module
import BulkAlertDelete from '../../../../assets/svgs/BulkAlertDelete';
import { useAlerts } from '../../../../hooks/useAlerts';

// Utility function to format end date
const formatEndDate = (endDate) => {
  if (!endDate) return 'No end date';
  const [year, month, day] = endDate;
  return `${new Date(year, month - 1, day).toDateString()}`;
};

// Main Component
const BulkAlertEarningsMobileView = ({ alertItems, text }) => {
  const { handleDeleteEconBulkAlertsData } = useAlerts();

  return (
    <div className={styles.alertList}>
      {alertItems.map((item, index) => (
        <div key={index} className={styles.alertItem}>
          <div className={styles.bulkItemSpacing}>
            <div className={styles.rowItem}>
              <span className={styles.country}>{item.company}</span>
            </div>
            {item.endDate && item.endDate.length > 0 && (
              <div className={styles.endDate}>
                End date: <span> {formatEndDate(item.endDate)} </span>{' '}
              </div>
            )}
          </div>
          <button
            className={styles.deleteButton}
            onClick={() => handleDeleteEconBulkAlertsData(item, text)}
          >
            <BulkAlertDelete />
          </button>
        </div>
      ))}
    </div>
  );
};

export default BulkAlertEarningsMobileView;
