import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const EditAlertMobile = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#a8a8a8' : '#161616';
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.0585 25.02L34.9785 25.94L25.9185 35H24.9985V34.08L34.0585 25.02ZM37.6585 19C37.4085 19 37.1485 19.1 36.9585 19.29L35.1285 21.12L38.8785 24.87L40.7085 23.04C41.0985 22.65 41.0985 22.02 40.7085 21.63L38.3685 19.29C38.1685 19.09 37.9185 19 37.6585 19ZM34.0585 22.19L22.9985 33.25V37H26.7485L37.8085 25.94L34.0585 22.19Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default EditAlertMobile;
