// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oSAG3g1aoMGb4dWoj2pb {
  padding: 2px 4px;
  font-size: 13px;
  border-radius: 1px;
  height: max-content;
}

.oZkM5hXPQfHi2Ty0cX0N {
  background: var(--high-importance-bg-color);
  color: var(--high-importance-text-color);
}

.gten8ORJXctc06KV2pIg {
  background: var(--low-importance-bg-color);
  color: var(--low-importance-text-color);
}

.PIiwJK2Ogqqvzkg1e6Q8 {
  background: var(--medium-importance-bg-color);
  color: var(--medium-importance-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/Global/ImportanceBadge.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,wCAAwC;AAC1C;;AAEA;EACE,0CAA0C;EAC1C,uCAAuC;AACzC;;AAEA;EACE,6CAA6C;EAC7C,0CAA0C;AAC5C","sourcesContent":[".badgeImportance {\n  padding: 2px 4px;\n  font-size: 13px;\n  border-radius: 1px;\n  height: max-content;\n}\n\n.highImp {\n  background: var(--high-importance-bg-color);\n  color: var(--high-importance-text-color);\n}\n\n.lowImp {\n  background: var(--low-importance-bg-color);\n  color: var(--low-importance-text-color);\n}\n\n.medImp {\n  background: var(--medium-importance-bg-color);\n  color: var(--medium-importance-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeImportance": `oSAG3g1aoMGb4dWoj2pb`,
	"highImp": `oZkM5hXPQfHi2Ty0cX0N`,
	"lowImp": `gten8ORJXctc06KV2pIg`,
	"medImp": `PIiwJK2Ogqqvzkg1e6Q8`
};
export default ___CSS_LOADER_EXPORT___;
