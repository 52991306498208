import EconCalendarTableData from './EconCalendarTableData';
import EconCalendarTabs from './EconCalendarTabs/EconCalendarTabs';
import style from '../styles/index.module.css';
import { useEconCalendarParams } from '../hooks/use-econ-calendar-params';
import { useEconCalendar } from '../hooks/useEconCalendar';
import MobileDetailsPage from './MobileDetailsPage';
import useScreenDimensions from '../hooks/useScreenDimension';
import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import MobileErrorScreen from './MobileErrorScreen';
import { useTranslation } from 'react-i18next';

export default function EconCalendar() {
  const { currentTheme, isWebsite, isAndroid, isIos, eventIdForDetails } = useEconCalendarParams();
  const { showDetail, showFilters, updateCalendarWidth } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const showFilterClass = showFilters ? style.tabFilterPageMobile : style.tabFilterMobile;
  const styleClass = isMobile ? showFilterClass : style.tabFilterDesktop;
  const [isOnline, setIsOnline] = useState(navigator?.onLine);
  const dialogRef = useRef(null);
  const { t } = useTranslation();

  let platformClass = '';

  if (isAndroid) platformClass = style.econAndroidFont;
  else if (isIos) platformClass = style.econIosFont;
  else platformClass = style.econWebFont;

  useEffect(() => {
    const currentDialog = dialogRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        for (let entry of entries) {
          updateCalendarWidth(entry.contentRect.width);
        }
      });
    });

    if (currentDialog) {
      resizeObserver.observe(currentDialog);
    }

    return () => {
      if (currentDialog) {
        resizeObserver.unobserve(currentDialog);
      }
    };
  }, []);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator?.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <div
      className={`${
        isMobile
          ? style.mainBackgroundMobile
          : isWebsite
            ? style.websiteContainer
            : style.mainBackground
      } ${platformClass}`}
      data-econ-calendar-theme={currentTheme}
      ref={dialogRef}
    >
      <div className={style.calendar_container}>
        {(!eventIdForDetails || (eventIdForDetails && isAndroid && showDetail)) && (
          <div className={styleClass}>
            <EconCalendarTabs />
          </div>
        )}
        {/* Show Table Data showDetail is for toggling the Rows detail to show the description and Chart (For Mobile view) */}
        {!isOnline && isMobile ? (
          <MobileErrorScreen errorMessage={'No network access.'} t={t} />
        ) : (
          !showFilters && (showDetail && !isIos ? <MobileDetailsPage /> : <EconCalendarTableData />)
        )}
      </div>
    </div>
  );
}
