import React from 'react';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function AlertIconWeb() {
  const { currentTheme } = useEconCalendarParams();

  const fillColor = currentTheme.toLowerCase() === 'dark' ? '#FFFFFF' : '#11203E';
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.51 21.26C10.51 22.36 11.4 23.25 12.5 23.25C13.6 23.25 14.49 22.36 14.49 21.26H10.51ZM12.5 6.25C15.26 6.25 17.5 8.49 17.5 11.25V18.25H7.5V11.25C7.5 8.49 9.74 6.25 12.5 6.25ZM12.5 1.75C11.67 1.75 11 2.42 11 3.25V4.42C7.86 5.1 5.5 7.9 5.5 11.25V17.25L3.5 19.25V20.25H21.5V19.25L19.5 17.25V11.25C19.5 7.9 17.14 5.1 14 4.42V3.25C14 2.42 13.33 1.75 12.5 1.75ZM13.5 8.25H11.5V11.25H8.5V13.25H11.5V16.25H13.5V13.25H16.5V11.25H13.5V8.25Z"
        fill={fillColor}
      />
    </svg>
  );
}
