import ImportanceFilterBox from '../../../Filters/ImportanceFilterBox';
import style from './BulkAlertSettings.module.css';
import styles from './BulkAlertsTabs.module.css';
import BulkAlertsSearchCompany from './BulkAlertsSearchCompany';
import { useEffect, useState } from 'react';
import { getEconData } from '../../../../api/EconCalendarApi';
import {
  continents,
  findContinentbyCountry,
  getCountryCode,
  getDateRangeAccountTime,
  getDateRangeLocal,
} from '../../../../utils/util';
import { useEconCalendar } from '../../../../hooks/useEconCalendar';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useSessionData } from '../../../../hooks/use-session-data';
import { getEnvURLs } from '../../../../utils/env-utils';
import AlertCountryFilter from './AlertCountryFilter';
import { accounts } from '../../../../utils/accountRegions';
import { countryCodeToNameMap } from '../../../../utils/countryCodeNameMapping';
import CustomDateInputWrapper from './CustomDateInputWrapper';

const importanceMap = {
  low: [0, 1],
  medium: [2],
  high: [3],
};
export default function BulkAlertsTabs({
  selectedImportance,
  extendedImportanceFilter,
  setExtendedImportanceFilter,
  handleImportance,
  allCountry,
  setAllCountry,
  selectedAlertType,
  showBulkAlertsDrawer,
  handleSubmitBulkAlert,
  formatDate,
  setCompanyName,
  selectedDate,
  setSelectedDate,
}) {
  const { activeTab, rawData } = useEconCalendar();
  const { locale, isAndroid, isIos } = useEconCalendarParams();
  const { timezoneOffset, environmentId, calendarApiUrl, igCompanySiteId } = useSessionData();
  const envURLs = getEnvURLs(environmentId);
  const authString = btoa(`${envURLs.tastyUserName}:${envURLs.tastyUserPassword}`);
  const [earningsSearchData, setEarningsSearchData] = useState([]);
  const [filteredBulkAlertsData, setFilteredAlertsData] = useState([]);
  const notWebsite = isAndroid || isIos;

  useEffect(() => {
    if (selectedAlertType === 'earningsEvents') {
      fetchEarningsEvents();
    }
  }, [selectedAlertType]);

  const handleCompanySelected = (name) => {
    setCompanyName(name);
  };

  const fetchEarningsEvents = async () => {
    try {
      const [fromDate, toDate] =
        timezoneOffset !== null
          ? getDateRangeAccountTime(activeTab, timezoneOffset, '')
          : getDateRangeLocal(activeTab, '');
      const resp = await getEconData(
        { activeTab: 'earnings-reports', fromDate, toDate, locale },
        // cst,
        authString,
        calendarApiUrl,
      );

      if (resp.status === 200) {
        const responseData = await resp.json();
        setEarningsSearchData(responseData);
      }
    } catch (error) {
      console.log(error, 'Err');
    }
  };

  useEffect(() => {
    if (
      (selectedImportance && selectedImportance.length > 0) ||
      (allCountry && allCountry.length > 0)
    ) {
      const filteredResponse =
        rawData &&
        rawData.length > 0 &&
        rawData.filter((item) => {
          const importanceMatch =
            selectedImportance?.length > 0
              ? selectedImportance.map((imp) => importanceMap[imp].includes(item.importance))
              : true;

          const countryMatch = allCountry?.length > 0 ? allCountry.includes(item.country) : true;

          return importanceMatch && countryMatch;
        });

      setFilteredAlertsData(filteredResponse);
      // Handle the filtered response as needed
    }
  }, [selectedImportance, allCountry]);

  const countryAccount =
    igCompanySiteId && accounts[igCompanySiteId]
      ? getCountryCode(accounts[igCompanySiteId])[0][0]
      : 'US';
  const countryContinent =
    igCompanySiteId && accounts[igCompanySiteId]
      ? findContinentbyCountry(igCompanySiteId)
      : 'Americas';

  if (!continents[countryContinent]?.includes(countryAccount)) {
    continents[countryContinent]?.push(countryAccount);
  }

  const totalCountryLength = Object.values(continents).reduce(
    (sum, value) => sum + value.length,
    0,
  );

  const handleSelectAll = () => {
    if (allCountry.length === totalCountryLength) {
      setAllCountry([]);
    } else {
      // Flatten all countries into a single array
      const countries = Object.values(continents).flat();
      // Map country codes to their names using the provided map
      const updatedCountries = countries.map((country) => countryCodeToNameMap[country]);
      // Update the state with the transformed data
      setAllCountry(updatedCountries);
    }
  };

  return selectedAlertType === 'macroeconomicEvents' ? (
    <div className={notWebsite ? style.macroWrapperMobile : style.macroWrapper}>
      <div className={notWebsite ? style.filterSectionMobile : style.filterSelection}>
        <div className={notWebsite ? style.flexSectionMobile : style.gridSection}>
          <p className={notWebsite ? style.textMobile : style.gridText}>
            {notWebsite ? 'Importance' : 'Importance Rating'}
          </p>
          <ImportanceFilterBox
            selectedImportance={selectedImportance}
            extendedImportanceFilter={extendedImportanceFilter}
            updateExtendedImportanceFilter={setExtendedImportanceFilter}
            handleImportance={handleImportance}
            showBulkAlertsDrawer={showBulkAlertsDrawer}
            isAlert={true}
          />
        </div>
        <div className={notWebsite ? style.flexSectionMobile : style.gridSection}>
          <p
            className={`${notWebsite ? style.textMobile : style.gridText} ${notWebsite ? style.textMobileCountry : style.none}`}
          >
            {notWebsite ? (
              <>
                <span>
                  Country ({allCountry.length}/{totalCountryLength})
                </span>
                <button className={style.selectAllBtn} onClick={handleSelectAll}>
                  {allCountry.length === totalCountryLength ? 'Unselect all' : 'Select all'}
                </button>
              </>
            ) : (
              'Country'
            )}
          </p>
          <AlertCountryFilter
            isAlert={true}
            allCountry={allCountry}
            setAllCountry={setAllCountry}
            totalCountryLength={totalCountryLength}
          />
        </div>
        <CustomDateInputWrapper
          notWebsite={notWebsite}
          selectedDate={selectedDate}
          formatDate={formatDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      {!notWebsite && (
        <button className={styles.setBulkAlert} onClick={handleSubmitBulkAlert}>
          Set bulk alerts
        </button>
      )}
    </div>
  ) : (
    <div className={styles.earningsContainer}>
      <div className={notWebsite ? styles.earningsSelectionBoxMobile : styles.earningsSelectionBox}>
        <div className={style.gridSection}>
          {!notWebsite && <p className={style.gridText}>Set alerts for specific companies</p>}
          <div className={notWebsite ? styles.searchBarMobile : styles.none}>
            <BulkAlertsSearchCompany
              data={earningsSearchData}
              onCompanySelected={handleCompanySelected}
            />
          </div>
        </div>
        <CustomDateInputWrapper
          notWebsite={notWebsite}
          selectedDate={selectedDate}
          formatDate={formatDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      {!notWebsite && (
        <div className={notWebsite ? style.setAlertWrapperMobile : style.none}>
          <button
            className={notWebsite ? style.setBulkAlertMobile : styles.setBulkAlert}
            onClick={handleSubmitBulkAlert}
          >
            Set bulk alerts
          </button>
        </div>
      )}
    </div>
  );
}
