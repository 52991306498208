import useScreenDimensions from '../hooks/useScreenDimension';
import style from '../styles/EarningsMobileHeadings.module.css';
import { useTranslation } from 'react-i18next';

const EarningsMobileHeadings = () => {
  const { t } = useTranslation();
  const isMobile = useScreenDimensions();
  return (
    <div
      className={`${style.headingsWrapper} ${isMobile ? style.mobileHeadingsWrapper : style.none}`}
    >
      <div className={style.emptyBox}></div>
      <div className={`${style.headings} calendar-regular`}>
        <span> {t('Actual')} </span>
        <span> {t('Forecast')} </span>
        <span> {t('Previous')} </span>
      </div>
    </div>
  );
};

export default EarningsMobileHeadings;
