import style from './BulkAlertSettings.module.css';
import styles from './BulkAlertsTabs.module.css';
import CustomDateInput from './CustomDateInput';

const CustomDateInputWrapper = ({ notWebsite, selectedDate, formatDate, setSelectedDate }) => {
  return (
    <div className={notWebsite ? style.flexSectionDateMobile : style.gridSection}>
      {((selectedDate === null && notWebsite) || !notWebsite) && (
        <p
          className={notWebsite ? `${style.textMobile} ${style.textMobileEndDate}` : style.gridText}
        >
          End Date
        </p>
      )}
      {selectedDate && notWebsite && (
        <div className={styles.selectedDateMobile}>
          <span className={styles.endDateMobile}> End Date </span>
          <span className={styles.formattedDateMobile}> {formatDate(selectedDate)} </span>
        </div>
      )}
      <CustomDateInput
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        notWebsite={notWebsite}
        formatDate={formatDate}
      />
    </div>
  );
};

export default CustomDateInputWrapper;
