import { useTranslation } from 'react-i18next';
import style from './BulkAlertSettingsTableData.module.css';
import DeleteIconAlerts from '../../../../assets/svgs/DeleteIconAlerts';
import { convertDateToString, importanceMapping } from '../../../../utils/util';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useSessionData } from '../../../../hooks/use-session-data';
import { useAlerts } from '../../../../hooks/useAlerts';

const BulkAlertSettingsTableData = ({ data, rowsInfo, selectedAlertType }) => {
  const { t } = useTranslation();
  const { locale } = useEconCalendarParams();
  const { timezoneOffset } = useSessionData();
  const { handleDeleteEconBulkAlertsData } = useAlerts();

  const handleCheckboxChange = (index, field) => {
    const updatedData = [...data];
    updatedData[index][field] = !updatedData[index][field];
    // setData(updatedData);
  };

  const handleDelete = (data) => {
    // const updatedData = data.filter((_, i) => i !== index);
    // // setData(updatedData);
    handleDeleteEconBulkAlertsData(data, selectedAlertType);
  };

  console.log(rowsInfo, data, 'rows info');

  const rowKeys = Object.keys(rowsInfo);
  const rowValues = Object.values(rowsInfo);

  const selectedTabData = data?.[selectedAlertType];

  return (
    <div className={style.tableContainer}>
      <table className={style.customTable}>
        <thead className={style.tableHead}>
          <tr className={style.tableRow}>
            {rowValues && rowValues.map((row, index) => <th key={index}>{row}</th>)}
          </tr>
        </thead>
        <tbody>
          {selectedAlertType === 'macroeconomicEvents'
            ? selectedTabData &&
              selectedTabData.length > 0 &&
              selectedTabData.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row[rowKeys[0]]?.join(', ') || '-'}</td>
                    <td>{row[rowKeys[1]]?.join(', ')}</td>
                    <td>
                      {row.endDate &&
                        row.endDate.length > 0 &&
                        convertDateToString(
                          row.endDate.join('-'),
                          timezoneOffset,
                          true,
                          locale != 'en' ? locale : undefined,
                          t,
                        )}
                    </td>
                    <td>
                      {row.lastTriggered &&
                        convertDateToString(
                          row[rowKeys[2]],
                          timezoneOffset,
                          true,
                          locale != 'en' ? locale : undefined,
                          t,
                        )}
                    </td>
                    <td>
                      <button onClick={() => handleDelete(row)} className={style.deleteIcon}>
                        <DeleteIconAlerts />
                      </button>
                    </td>
                  </tr>
                );
              })
            : selectedTabData &&
              selectedTabData.length > 0 &&
              selectedTabData.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row[rowKeys[0]] || '-'}</td>
                    <td>
                      {row.endDate &&
                        row.endDate.length > 0 &&
                        convertDateToString(
                          row.endDate.join('-'),
                          timezoneOffset,
                          true,
                          locale != 'en' ? locale : undefined,
                          t,
                        )}
                    </td>
                    <td>
                      {convertDateToString(
                        row[rowKeys[2]],
                        timezoneOffset,
                        true,
                        locale != 'en' ? locale : undefined,
                        t,
                      )}
                    </td>
                    <td>
                      <button onClick={() => handleDelete(row)} className={style.deleteIcon}>
                        <DeleteIconAlerts />
                      </button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default BulkAlertSettingsTableData;
