import { useMediaQuery } from 'react-responsive';
import { useEconCalendarParams } from './use-econ-calendar-params';

const useScreenDimensions = () => {
  const { isIos, isAndroid, isWebsite } = useEconCalendarParams();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  if (isIos || isAndroid) return true;
  else return isMobile && isWebsite;
};

export default useScreenDimensions;
