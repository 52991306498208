import '../styles/Resulting.css'
import { useTranslation } from '@ig-caa/i18n'
import { useState } from 'react'
import { formatNumber, formatNumberString } from '../utils/formatNumber'
import styled from 'styled-components'
import { Directions, GATracking } from '../shared-types'
import { Tooltip, Notification } from 'ig-phoenix'

interface ResultingProps {
  clientLocale?: string
  currencyName?: string
  currencySymbol?: string
  valueOfOnePip?: number
  onePipMeans?: number
  valueSize?: number
  valueOpening?: number
  valueClosing?: number
  selectedDirection: Directions
  decimalPlacesFactor: number
  isShares: boolean
  epic: string
  instrumentName: string
  gaTracking: GATracking
}

interface CalcTotalInputs {
  selectedDirection: Directions
  decimalPlacesFactor: number
  valueOfOnePip?: number
  onePipMeans?: number
  valueSize?: number
  valueOpening?: number
  valueClosing?: number
}

const calcTotal = ({
  selectedDirection,
  decimalPlacesFactor,
  valueOfOnePip,
  onePipMeans,
  valueSize,
  valueOpening,
  valueClosing
}: CalcTotalInputs) => {
  if (
    (valueOfOnePip !== undefined && valueOfOnePip !== 0) &&
    (onePipMeans !== undefined) &&
    (valueSize !== undefined && valueSize !== 0) &&
    (valueOpening !== undefined) &&
    (valueClosing !== undefined)
  ) {
    if (selectedDirection === 'sell') {
      return (formatNumber(valueSize) * formatNumber(valueOfOnePip, 2) * (formatNumber(+valueOpening, decimalPlacesFactor) - formatNumber(+valueClosing, decimalPlacesFactor))) /
        (onePipMeans === 0 ? 1 : onePipMeans)
    } else if (selectedDirection === 'buy') {
      return (formatNumber(valueSize) * formatNumber(valueOfOnePip, 2) * (formatNumber(+valueClosing, decimalPlacesFactor) - formatNumber(+valueOpening, decimalPlacesFactor))) /
        (onePipMeans === 0 ? 1 : onePipMeans)
    }
  } else {
    return undefined
  }
}

const formatTotal = (
  clientLocale?: string,
  currencyName?: string,
  total?: number
) =>
  (!(total === undefined || isNaN(total)) && currencyName && clientLocale)
    ? Intl.NumberFormat(
      clientLocale,
      { style: 'currency', currency: (currencyName === 'GBX' ? 'GBP' : currencyName), maximumFractionDigits: 2 }
    ).format(currencyName === 'GBX' ? total / 100 : total)
    : '-'

const NegativeValue = styled.span`color: ${({ theme }) => theme.color.text.negativeAmount.value};`

const PositiveValue = styled.span`color: ${({ theme }) => theme.color.text.positiveAmount.value};`

export default function Resulting (props: ResultingProps): JSX.Element {
  const { t } = useTranslation()
  const {
    clientLocale, currencyName, currencySymbol, valueOfOnePip, onePipMeans, valueSize, valueOpening, valueClosing,
    selectedDirection, decimalPlacesFactor, isShares, epic, instrumentName, gaTracking
  } = props

  const [isExpanded, setIsExpanded] = useState(false)

  const total = calcTotal({ selectedDirection, decimalPlacesFactor, valueOfOnePip, onePipMeans, valueSize, valueOpening, valueClosing })

  const validTotal = !(total === undefined || isNaN(total) || !clientLocale || !currencyName)

  const priceTooltip = (priceLevel: 'opening' | 'closing', priceId: 'first-price' | 'second-price'): JSX.Element => (
    <Tooltip
      position='bottom'
      zIndex='9'
      tipPosition={`${priceId === 'first-price' ? '-136px' : '-162px'}`}
      top='25px'
      width={`${priceLevel === 'opening' ? '245px' : '242px'}`}
      tooltipRender={() =>
        <div className={'tooltip-body-text'} data-testid={`tooltip-body-${priceLevel}`}>{t(`${priceLevel}-price-hover-info`)}</div>
      }
    >
      <span className='text-with-tooltip' data-testid={`tooltip-${priceId}`}>{t(`${priceLevel}-price-level`)}</span>
    </Tooltip>
  )

  return (
    <div className='resulting-PnL'>
      <div className='same-row'>
        <button
          className='expander'
          data-testid='expander'
          onClick={function () {
            setIsExpanded(!isExpanded)
            gaTracking(epic, instrumentName, 'Expanding button', 'Interacting P&L calculator tool')
          }}
        >
          {isExpanded ? '-' : '+'}
        </button>
        <span className='resulting-header'>{t('resulting-p-n-l')}</span>
        <div
          className={'total-resulting'}
          data-testid={`${validTotal
            ? total >= 0
              ? 'total-numeric'
              : 'total-numeric-negative'
            : 'total-empty'}`}
        >
          {validTotal && total < 0 && (
            <NegativeValue>{formatTotal(clientLocale, currencyName, total).replace('.00', '')}</NegativeValue>
          )}
          {validTotal && total > 0 && (
            <PositiveValue>{formatTotal(clientLocale, currencyName, total).replace('.00', '')}</PositiveValue>
          )}
          {(!validTotal || total === 0) &&
            formatTotal(clientLocale, currencyName, total).replace('.00', '')}
        </div>
      </div>
      {!(valueOfOnePip && (onePipMeans || onePipMeans === 0) && clientLocale && currencyName) &&
        <div className='error-notification'>
          <Notification
            variant='warning'
            maxWidth='350px'
            onCloseClick={
              /* istanbul ignore next */
              () => { }
            }>
            <Notification.Body>{t('error-info')}</Notification.Body>
          </Notification>
        </div>

      }
      {isExpanded && (
        <>
          <div
            className='text-calculation-section same-row'
            data-testid='word-equation'
          >
            <p className='equals'>=</p>
            <div className='same-column'>
              <p className={`same-row noMargin + ${(isShares || onePipMeans === 0) ? '' : ' underlined'}`}>
                <Tooltip
                  position='bottom'
                  zIndex='9'
                  tipPosition='-15px'
                  top='25px'
                  width='295px'
                  tooltipRender={() => (
                    <div className='tooltip-body-text' data-testid={'tooltip-body-size'}>{t('size-hover-info')}</div>
                  )}
                >
                  <span className='text-with-tooltip' data-testid={'tooltip-size'}>{t('size')}</span>
                </Tooltip>
                {isShares
                  ? null
                  : <>
                      <span className='operator'>x</span>
                      <Tooltip
                        position='bottom'
                        zIndex='9'
                        tipPosition='-55px'
                        top='25px'
                        width='416px'
                        tooltipRender={() => (
                          <div className='tooltip-valueOfOnePip-text' data-testid={'tooltip-body-valueOfOnePip'}>
                            <div className='valueOfOnePip-hover-text'>
                              <p className='valueOfOnePip-hover-info'>{t('valueOfOnePip-hover-info')}</p>
                              <p className='valueOfOnePip-hover-disclaimer'>{t('hover-info-disclaimer')}</p>
                            </div>
                            <div className='valueOfOnePip-hover-image'></div>
                          </div>
                        )}
                      >
                        <span className='text-with-tooltip' data-testid={'tooltip-valueOfOnePip'}>{t('value-one-pip')}</span>
                      </Tooltip>
                    </>}
                <span className='operator'>x (</span>
                {selectedDirection === 'sell'
                  ? priceTooltip('opening', 'first-price')
                  : priceTooltip('closing', 'first-price')
                }
                <span className='operator'>-</span>
                {selectedDirection === 'sell'
                  ? priceTooltip('closing', 'second-price')
                  : priceTooltip('opening', 'second-price')
                }
                <span className='operator'>)</span>
              </p>
              {(isShares || onePipMeans === 0)
                ? null
                : <Tooltip
                    position='bottom'
                    zIndex='9'
                    tipPosition='-55px'
                    top='25px'
                    width='416px'
                    tooltipRender={() => (
                      <div className='tooltip-onePipMeans-text' data-testid={'tooltip-body-onePipMean'}>
                        <div className='onePipMeans-hover-text'>
                          <p className='onePipMeans-hover-info'>{t('onePipMeans-hover-info')}</p>
                          <p className='onePipMeans-hover-disclaimer'>{t('hover-info-disclaimer')}</p>
                        </div>
                        <div className='onePipMeans-hover-image'></div>
                      </div>
                    )}
                  >
                    <p className='text-with-tooltip noMargin' data-testid={'tooltip-onePipMean'}>{t('one-pip-means')}</p>
                  </Tooltip>}
            </div>
          </div>
          <div className='text-calculation-section same-row' data-testid='number-equation'>
            <p className='equals'>=</p>
            <div className='same-column'>
              <p className={`same-row noMargin ${(isShares || onePipMeans === 0) ? '' : ' underlined'}`}>
                <span>{valueSize ? formatNumberString(valueSize).replace('.00', '') : '-'}</span>
                {isShares
                  ? null
                  : <>
                      <span className='operator'>x</span>
                      <span>
                        {currencySymbol && valueOfOnePip && valueOfOnePip > 0 ? currencySymbol : ''}
                        {valueOfOnePip && valueOfOnePip > 0 ? formatNumberString(valueOfOnePip, 2) : '-'}
                      </span>
                    </>}
                <span className='operator'>x (</span>
                <span data-testid="first-price-figure">{
                  selectedDirection === 'sell'
                    ? (valueOpening === undefined ? '-' : formatNumberString(valueOpening, decimalPlacesFactor))
                    : (valueClosing === undefined ? '-' : formatNumberString(valueClosing, decimalPlacesFactor))
                }</span>
                <span className='operator'>-</span>
                <span data-testid="second-price-figure">{
                  selectedDirection === 'sell'
                    ? (valueClosing === undefined ? '-' : formatNumberString(valueClosing, decimalPlacesFactor))
                    : (valueOpening === undefined ? '-' : formatNumberString(valueOpening, decimalPlacesFactor))
                }</span>
                <span className='operator'>)</span>
              </p>
              {(isShares || onePipMeans === 0)
                ? null
                : <p className='noMargin'>{onePipMeans || '-'}</p>}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
