import React, { FC } from "react";
import { Container, Title, Text, Grid } from "@cet/components";
import { Account, BasicInfoService, t } from "@cet/services";
import { CountryCode } from "@cet/interfaces";
import { AccountFunctionality } from "@cet/constants";
import {
  CCTabContent,
  ExAnteTabContent,
  InducementTabContent,
  LeveragedTabContent,
  PhysicalTabContent,
  StatementsTabsContainer,
} from "./containers";
import { IStatementsTab, StatementsType } from "./types";
import { AdobeInfo } from "./components";
import { ActionType, ReportType, SelectedPeriod } from "./store";
import { StatementsContext } from "./hooks";
import EligibleInvoice from "./containers/EligibleInvoice/EligibleInvoice";
import { TastyTabContent } from "./containers/TastyTabContent";
import { IFUTabContent } from "./containers/IFUTabContent";

type StatementsProps = {
  account: Account;
  locale: string;
  showTitle?: boolean;
};

export const Statements: FC<StatementsProps> = ({
  account,
  locale,
  showTitle = false,
}) => {
  const basicInfoService = BasicInfoService.getInstance<BasicInfoService>();
  const renderUSDisclosuresAndRisk = basicInfoService.isClientFromCountry(
    CountryCode.US,
  );
  const statementsTabs: IStatementsTab[] = [];
  const isCC: boolean =
    !basicInfoService.isClientFromCountry(CountryCode.Australia) &&
    !basicInfoService.isClientFromCountry(CountryCode.China) &&
    !basicInfoService.isClientFromCountry(CountryCode.Switzerland) &&
    !basicInfoService.isClientFromCountry(CountryCode.US) &&
    !basicInfoService.isClientFromCountry(CountryCode.Japan);
  const isExAnte = account.getAccountFunctionality(
    AccountFunctionality.ExAnte,
  ).active;
  const isIFU = basicInfoService.isClientFromCountry(CountryCode.France);

  if (account.isPhysical) {
    statementsTabs.push({
      actionType: ActionType.PHYSICAL,
      availableForPOA: true,
      label: t("Statements.Physical.title"),
      options: [
        SelectedPeriod.daily,
        SelectedPeriod.monthly,
        SelectedPeriod.quarterly,
        SelectedPeriod.annual,
      ],
      statementsType: StatementsType.PHYSICAL,
      tabContent: PhysicalTabContent,
      usDisclosure: renderUSDisclosuresAndRisk,
    });
  } else if (account.isTasty) {
    statementsTabs.push({
      actionType: ActionType.TASTYDAILY,
      availableForPOA: false,
      label: t("Statements.tasty.daily"),
      options: [ReportType.transactional],
      statementsType: StatementsType.TASTYDAILY,
      tabContent: TastyTabContent,
      usDisclosure: false,
    });
    statementsTabs.push({
      actionType: ActionType.TASTYMONTHLY,
      availableForPOA: false,
      label: t("Statements.tasty.monthly"),
      options: [ReportType.transactional],
      statementsType: StatementsType.TASTYMONTHLY,
      tabContent: TastyTabContent,
      usDisclosure: false,
    });
    statementsTabs.push({
      actionType: ActionType.TASTYYEARLY,
      availableForPOA: false,
      label: t("Statements.tasty.yearly"),
      options: [ReportType.costandcharges, ReportType.uktaxstatement, ReportType.ustaxstatement],
      statementsType: StatementsType.TASTYYEARLY,
      tabContent: TastyTabContent,
      usDisclosure: false,
    });
  } else {
    statementsTabs.push({
      actionType: ActionType.LEVERAGED,
      availableForPOA: true,
      label: t("Statements.Leveraged.title"),
      options: [
        SelectedPeriod.daily,
        SelectedPeriod.monthly,
        SelectedPeriod.quarterly,
        SelectedPeriod.annual,
      ],
      statementsType: StatementsType.LEVERAGED,
      tabContent: LeveragedTabContent,
      usDisclosure: renderUSDisclosuresAndRisk,
    });
  }

  if (isCC && !account.isTasty) {
    statementsTabs.push({
      actionType: ActionType.CC,
      availableForPOA: true,
      label: t("Statements.costsAndCharges.title"),
      options: [SelectedPeriod.annual],
      statementsType: StatementsType.CC,
      tabContent: CCTabContent,
      usDisclosure: false,
    });
  }

  if (isCC && !!account.ibCode && !account.isTasty) {
    statementsTabs.push({
      actionType: ActionType.INDUCEMENT,
      availableForPOA: true,
      label: t("Statements.Inducement.title"),
      options: [SelectedPeriod.monthly],
      statementsType: StatementsType.INDUCEMENT,
      tabContent: InducementTabContent,
      usDisclosure: false,
    });
  }

  if (isExAnte) {
    statementsTabs.push({
      actionType: undefined,
      availableForPOA: true,
      label: t("Statements.ExAnte.title"),
      options: undefined,
      statementsType: StatementsType.EXANTE,
      tabContent: ExAnteTabContent,
      usDisclosure: false,
    });
  }

  if(isIFU) {
    statementsTabs.push({
      actionType: ActionType.IFU,
      availableForPOA: true,
      label: t("Statements.IFU.title"),
      options: [SelectedPeriod.annual],
      statementsType: StatementsType.IFU,
      tabContent: IFUTabContent,
      usDisclosure: false,
    });
  }

  if (
    account.getAccountFunctionality(AccountFunctionality.Statements)?.config
      ?.JTM
  ) {
    statementsTabs.push({
      actionType: undefined,
      availableForPOA: false,
      label: t("Statements.EligibleInvoice.title"),
      options: undefined,
      statementsType: StatementsType.JTM,
      tabContent: EligibleInvoice,
      usDisclosure: false,
    });
  }

  return (
    <StatementsContext.Provider value={{ account, locale }}>
      <Container>
        {showTitle && (
          <Title dataTestId="statements.title" label={t("Statements.title")} />
        )}
        {account.isTasty &&
          <Grid.Col>
            <Grid.Row mt={[4]}>
              <Text data-testid='statements.tasty.description' variant='BodyMedium' dangerouslySetInnerHTML={{ __html: t("statements.tasty.description") }} />
            </Grid.Row>
            <Grid.Row my={[3]}>
              <Text color="grey" data-testid='statements.tasty.description1' variant='BodyMedium' dangerouslySetInnerHTML={{ __html: t("statements.tasty.description1") }} />
            </Grid.Row>
          </Grid.Col>
        }
        <StatementsTabsContainer tabs={statementsTabs} account={account} />
      </Container>
      <AdobeInfo />
    </StatementsContext.Provider>
  );
};
