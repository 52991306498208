// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YtpMXLISUfIYRjFhTBCd {
  display: flex;
  justify-content: space-between;
  padding: 16px 12px 4px;
}

.ZoEA3SyaIbPmAKmKWkxy {
  text-transform: uppercase;
  color: var(--mobile-text-secondary);
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-decoration-skip-ink: none;
}

.__WToPd_AflNQGRtz2gP {
  all: unset;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--ios-customDateBtn-text-color);
}

.kNOIhqpgdW6oF929cOrv {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  border: none;
  outline: none;
  background-color: var(--mobile-data-region-background-color);
  color: var(--ios-customDateBtn-text-color);
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alerts/ManageAlerts/BulkAlerts/BulkAlertMobileData.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,mCAAmC;EACnC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kCAAkC;EAClC,8BAA8B;EAC9B,0CAA0C;AAC5C;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,4DAA4D;EAC5D,0CAA0C;EAC1C,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".bulkContainer {\n  display: flex;\n  justify-content: space-between;\n  padding: 16px 12px 4px;\n}\n\n.headTitle {\n  text-transform: uppercase;\n  color: var(--mobile-text-secondary);\n  font-size: 13px;\n  font-weight: 400;\n  line-height: 15px;\n  text-decoration-skip-ink: none;\n}\n\n.deleteAllBtn {\n  all: unset;\n  font-size: 17px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: -0.5px;\n  text-underline-position: from-font;\n  text-decoration-skip-ink: none;\n  color: var(--ios-customDateBtn-text-color);\n}\n\n.viewMoreBtn {\n  padding: 10px 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 44px;\n  border: none;\n  outline: none;\n  background-color: var(--mobile-data-region-background-color);\n  color: var(--ios-customDateBtn-text-color);\n  font-size: 17px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: -0.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bulkContainer": `YtpMXLISUfIYRjFhTBCd`,
	"headTitle": `ZoEA3SyaIbPmAKmKWkxy`,
	"deleteAllBtn": `__WToPd_AflNQGRtz2gP`,
	"viewMoreBtn": `kNOIhqpgdW6oF929cOrv`
};
export default ___CSS_LOADER_EXPORT___;
