import { useState } from 'react';
import { alertSettingsConfigMobile } from '../../../../utils/alertUtils';
import style from './AlertSettings.module.css';
import AlertSettingsTableData from './AlertSettingsTableData';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import CrossIconCalendarTypeIos from '../../../../assets/svgs/CrossIconCalendarTypeIos';
import AlertSettingOptions from './AlertSettingOptions';
import { useAlerts } from '../../../../hooks/useAlerts';
import NoAlertsSetMobile from '../NoAlertsSetMobile';

export default function AlertSettings() {
  const {
    tabAlertData: alertsData,
    isAlertsLoading,
    showDefaultSettingsMobile,
    handleDeleteAllAlerts,
    updateShowDefaultSettingsMobile,
    handlePostAlertSettings,
    selectedAlertType,
  } = useAlerts();
  const [showDeleteAllConfirmationPopUp, setShowDeleteAllConfirmationPopUp] = useState(false);
  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  const { isIos, isAndroid, currentTheme } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;
  const settingsKeys = Object.keys(alertSettingsConfigMobile);

  const updateShowInfo = (value) => {
    if (showInfoPopUp !== value) setShowInfoPopUp(value);
  };

  const handleDone = async () => {
    try {
      await handlePostAlertSettings();
    } catch (error) {
      console.log(error, 'Err');
    } finally {
      updateShowDefaultSettingsMobile(false);
    }
  };

  return (
    <div
      className={`${style.alertSettingsContainer} ${currentTheme === 'dark' ? style.darkTheme : style.lightTheme}`}
    >
      {notWebsite ? (
        <div className={style.defaultTextWrapper}>
          <button
            className={`${style.defaultTextButton} ${alertsData && alertsData.length > 0 ? style.notDisabled : style.disabled}`}
            onClick={() => updateShowDefaultSettingsMobile(true)}
            disabled={!(alertsData && alertsData.length > 0)}
          >
            Default Settings
          </button>
        </div>
      ) : (
        <p className={style.defaultText}>Default Settings</p>
      )}
      {notWebsite && showDefaultSettingsMobile && <div className={style.modal}></div>}
      {((notWebsite && showDefaultSettingsMobile) || !notWebsite) && (
        <div className={notWebsite ? style.alertSettingsMobile : style.alertSettings}>
          {notWebsite && showDefaultSettingsMobile && (
            <div className={style.defaultSettingsMobileHeaderWrapper}>
              <div className={style.defaultSettingsMobileHeader}>
                {!isAndroid && <button>Reset</button>}
                <span>Default Settings</span>
              </div>
              <button onClick={() => updateShowDefaultSettingsMobile(false)}>
                <CrossIconCalendarTypeIos />
              </button>
            </div>
          )}
          <AlertSettingOptions settingsKeys={settingsKeys} updateShowInfo={updateShowInfo} />
        </div>
      )}
      {notWebsite && showDefaultSettingsMobile && (
        <div className={style.defaultSettingDoneBtnWrapper}>
          <button onClick={() => handleDone()}>Done</button>
        </div>
      )}
      {notWebsite && showInfoPopUp && (
        <>
          <div className={style.infoModal}></div>
          <div className={style.showInfoWrapper}>
            <div className={style.showInfoBody}>
              <span> Alert me </span>
              <span>
                Pre-event warning applicable to macro-economic events only, as earnings are not
                announced at an exact pre-arranged time (only pre-open, after-close etc.)
              </span>
            </div>
            <button className={style.showInfoSectionBtn} onClick={() => updateShowInfo(false)}>
              OK
            </button>
          </div>
        </>
      )}
      {notWebsite && showDeleteAllConfirmationPopUp && (
        <>
          <div className={style.modal}></div>
          <div className={style.deleteAllMobileWrapper}>
            <div className={style.deleteAllMobileHeader}>
              <span>Delete all alerts</span>
              <button onClick={() => setShowDeleteAllConfirmationPopUp(false)}>
                <CrossIconCalendarTypeIos />
              </button>
            </div>
            <div className={style.deleteAllMobileBody}>
              <p>
                This will delete all your alerts. This cannot be undone. Are you sure you want to
                proceed?
              </p>
            </div>
            <div className={style.deleteAllMobileBtns}>
              <button
                onClick={async () => {
                  await handleDeleteAllAlerts(selectedAlertType);
                  setShowDeleteAllConfirmationPopUp(false);
                }}
              >
                Delete all
              </button>
              <button onClick={() => setShowDeleteAllConfirmationPopUp(false)}> Cancel </button>
            </div>
          </div>
        </>
      )}
      <div>
        {!isAlertsLoading && (
          <div
            className={notWebsite ? style.alertDetailContainerMobile : style.alertDetailContainer}
          >
            {!notWebsite ? (
              <p className={style.alertDetailText}>
                You have alerts set for {alertsData?.length} economic events
              </p>
            ) : (
              alertsData &&
              alertsData.length > 0 && (
                <div className={style.alertDetailWrapperMobile}>
                  <span> EVENT ALERTS </span>
                  <button onClick={() => setShowDeleteAllConfirmationPopUp(true)}>
                    Delete all
                  </button>
                </div>
              )
            )}
            {alertsData && alertsData.length > 0 ? (
              <AlertSettingsTableData data={alertsData} />
            ) : notWebsite ? (
              <NoAlertsSetMobile config="alerts" />
            ) : (
              <div className={style.noEventsDiv}>
                <p className={style.noEventsToDisplay}>No events to display</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
