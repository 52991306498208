const NoAlertsMobile = () => {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_6773_103907"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-7"
        width="80"
        height="79"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.5 31.8182C76.6656 27.7346 79.25 22.0441 79.25 15.75C79.25 3.32359 69.1764 -6.75 56.75 -6.75C50.4559 -6.75 44.7654 -4.16557 40.6818 0H0.5V72H72.5V31.8182ZM56.75 38.25C62.8823 38.25 68.4416 35.7968 72.5 31.8182V0H40.6818C36.7032 4.05844 34.25 9.61773 34.25 15.75C34.25 28.1764 44.3236 38.25 56.75 38.25Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_6773_103907)">
        <path
          d="M39.3005 3.19694L39.3011 3.19703L39.3016 3.19711L39.3019 3.19717L39.3025 3.19726L39.3039 3.19748L39.3053 3.1977L39.3234 3.2006L39.3463 3.2039C48.985 4.593 56.4181 12.8904 56.4181 22.8903V32.018C56.4181 39.3765 59.6424 46.3269 65.2608 51.081C65.9044 51.6349 66.2401 52.3892 66.2401 53.1744C66.2401 54.6774 65.0133 55.9043 63.5102 55.9043H9.48952C7.9873 55.9043 6.76025 54.6777 6.76025 53.1744C6.76025 52.3784 7.1041 51.6282 7.70922 51.107C13.3556 46.3291 16.5822 39.3796 16.5822 32.018V22.8903C16.5822 12.89 24.0121 4.59301 33.6539 3.20392L33.6769 3.20061L33.6951 3.1977L33.6964 3.19748L33.6978 3.19726L33.6985 3.19717L33.6988 3.19711L33.6993 3.19703L33.6998 3.19694L33.7289 3.19261C33.7594 3.18813 33.8099 3.18092 33.8781 3.17187C34.0148 3.15373 34.2214 3.12838 34.4809 3.10271C35.0041 3.05092 35.7204 3 36.4999 3C37.2797 3 37.9961 3.05093 38.5194 3.10271C38.7789 3.12839 38.9855 3.15373 39.1223 3.17187C39.1905 3.18092 39.2409 3.18813 39.2715 3.19261L39.3005 3.19694Z"
          stroke="#8D8D8D"
          strokeWidth="6"
        />
        <path
          d="M36.5003 72.0002C42.4298 72.0002 47.39 67.7732 48.529 62.1782H24.4717C25.6113 67.7732 30.5714 72.0002 36.5003 72.0002Z"
          fill="#8D8D8D"
        />
      </g>
      <path d="M56.75 0V31.5" stroke="#8D8D8D" strokeWidth="6" />
      <path d="M41 15.75L72.5 15.75" stroke="#8D8D8D" strokeWidth="6" />
    </svg>
  );
};

export default NoAlertsMobile;
