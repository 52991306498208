import { Alert, Button, Input, LabelledField } from "ig-phoenix";
import { FormEventHandler } from "react";
import styled from "styled-components";
import useLoginWithRedirect from "../hooks/useLoginWithRedirect";
import getFormData from "../utils/form-extractor";


const AlertSkeleton = styled.div`
  height: 54px
`

export default function LoginForm(props) {
  const loginMutation = useLoginWithRedirect();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = getFormData(event.target, 'username', 'password');
    loginMutation.mutate(formData);
  }

  return (
    <form onSubmit={handleSubmit}>
      <LabelledField labelText="Username" htmlFor="username">
        <Input title="username" onChange={() => {}} id="username" />
      </LabelledField>
      <LabelledField labelText="Password" htmlFor="password">
        <Input title="password" onChange={() => {}} id="password" type="password" />
      </LabelledField>
      
      {loginMutation.isError ? 
        <Alert variant='critical'>
          {`${loginMutation.error}`}
        </Alert>
        : <AlertSkeleton />
      }
      <Button onClick={() => {}}>{loginMutation.isLoading ? 'loading...' : 'Login'}</Button>
    </form>
  );
}
