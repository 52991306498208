import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function MobileFilterExpandIcon() {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203E';
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 18V20H33V18H15ZM22 30H26V28H22V30ZM30 25H18V23H30V25Z"
        fill={fillColor}
      />
    </svg>
  );
}
