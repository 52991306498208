import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const CrossIconCalendarTypeIos = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#a8a8a8' : '#000';
  const strokeColor = currentTheme === 'dark' ? '#fff' : '#000';
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3786 21.5723C21.2241 21.7261 21.0145 21.8125 20.7959 21.8125C20.5772 21.8125 20.3676 21.7261 20.2131 21.5723L15.0248 16.4124L9.84137 21.5679C9.519 21.8851 8.99954 21.8837 8.67889 21.5648C8.35824 21.2459 8.35687 20.7292 8.67582 20.4087L13.8594 15.2533L8.67677 10.0989C8.36285 9.77756 8.36655 9.26506 8.68507 8.94822C9.00359 8.63138 9.5189 8.62761 9.84207 8.93976L15.0249 14.0941L20.2122 8.93526C20.4194 8.72364 20.7251 8.63931 21.0124 8.71454C21.2996 8.78977 21.524 9.01292 21.5995 9.29861C21.6751 9.58431 21.5903 9.88836 21.3774 10.0944L16.1905 15.2533L21.3788 20.4133C21.5333 20.567 21.6201 20.7755 21.6201 20.9928C21.6201 21.2102 21.5332 21.4187 21.3786 21.5723Z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="0.9375"
      />
    </svg>
  );
};

export default CrossIconCalendarTypeIos;
