import React from 'react';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const ToEnableAlert = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#11203e';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1183_47870"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-2"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 7.07072C16.9257 6.16326 17.5 4.8987 17.5 3.5C17.5 0.738576 15.2614 -1.5 12.5 -1.5C11.1013 -1.5 9.83674 -0.925681 8.92928 0H0V16H16V7.07072ZM12.5 8.5C13.8627 8.5 15.0981 7.95484 16 7.07072V0H8.92928C8.04516 0.901875 7.5 2.13727 7.5 3.5C7.5 6.26142 9.73858 8.5 12.5 8.5Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1183_47870)">
        <path
          d="M8.60387 0.791917L8.60929 0.792729L8.60928 0.792808L8.62074 0.794459C10.7225 1.09735 12.343 2.90678 12.343 5.08673V7.11512C12.343 8.77475 13.0702 10.3425 14.3373 11.4148C14.462 11.5222 14.5257 11.6668 14.5257 11.8165C14.5257 12.1045 14.2904 12.3398 14.0024 12.3398H1.99778C1.70998 12.3398 1.47461 12.1046 1.47461 11.8165C1.47461 11.664 1.54034 11.5204 1.65633 11.4204C2.92951 10.3429 3.65726 8.77547 3.65726 7.11512V5.08673C3.65726 2.90668 5.27711 1.09736 7.37952 0.794464L7.37953 0.794553L7.3897 0.792926L7.39012 0.79286L7.3902 0.792849L7.3905 0.792799L7.39074 0.792762L7.39087 0.792741L7.39643 0.791917L7.42842 0.787468C7.458 0.783545 7.50298 0.778023 7.55962 0.772418C7.67398 0.761101 7.83031 0.75 8.00008 0.75C8.16993 0.75 8.3263 0.761102 8.44067 0.772419C8.49731 0.778024 8.5423 0.783545 8.57188 0.787469L8.60387 0.791917Z"
          stroke={fillColor}
          strokeWidth="1.5"
        />
        <path
          d="M8.00018 16C9.31785 16 10.4201 15.0607 10.6732 13.8174H5.32715C5.5804 15.0607 6.68265 16 8.00018 16Z"
          fill={fillColor}
        />
      </g>
      <path d="M12.5 0V7" stroke={fillColor} strokeWidth="1.5" />
      <path d="M9 3.5L16 3.5" stroke={fillColor} strokeWidth="1.5" />
    </svg>
  );
};

export default ToEnableAlert;
