import BackArrowiOS from '../../assets/svgs/BackArrowiOS';
import BackArrow from '../../assets/svgs/BackArrow';
import CrossIcon from '../../assets/svgs/CrossIcon';
import { TFunction } from 'i18next';
import style from '../../styles/EconCalendarTabs.module.css';

interface MobileNavButtonProps {
  isMobile: boolean;
  isIos: boolean;
  showFilters: boolean;
  handleButtonClick: () => void;
  t: TFunction;
}

const MobileNavButton: React.FC<MobileNavButtonProps> = ({
  isMobile,
  isIos,
  showFilters,
  handleButtonClick,
  t,
}) => {
  if (!isMobile) return null;

  return (
    <button
      className={`${showFilters ? style.crossIcon : style.backArrow} ${!showFilters && isIos ? style.iosBackArrow : style.none}`}
      onClick={handleButtonClick}
    >
      {showFilters ? (
        <CrossIcon data-testid="mobile-cross-icon" />
      ) : isIos ? (
        <BackArrowiOS width="12" height="18" data-testid="mobile-back-icon" />
      ) : (
        <BackArrow data-testid="mobile-back-icon" />
      )}
    </button>
  );
};

export default MobileNavButton;
