import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import styled from 'styled-components'
import { MenuArrowDown } from '@ig-caa/media'
import { Theme, visibleColumnType } from '../AppProps'

interface ColumnFilterType {
  visibleColumns: visibleColumnType
  setVisibleColumns: Dispatch<SetStateAction<visibleColumnType>>
  theme: Theme
  trackingProps: Object
}

export default function ColumnDisplayFilter ({ visibleColumns, setVisibleColumns, theme, trackingProps }: ColumnFilterType) {
  const [isOpen, setIsOpen] = useState(false)

  /* istanbul ignore next */
  const checkboxColor = (theme === 'Light') ? 'black' : ''

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const columnMenu = useRef(null)
  const columnButton = useRef(null)

  const closeColumnMenu = (e: any) => {
    // @ts-expect-error
    if (isOpen && !columnMenu.current?.contains(e.target) && !columnButton.current?.contains(e.target)) {
      setIsOpen(false)
    }
  }

  document.addEventListener('mousedown', closeColumnMenu)

  const toggleFilter = (e: React.MouseEvent) => {
    const selectedColumn = e.currentTarget.textContent
    /* istanbul ignore next */
    if (!selectedColumn) return
    /* istanbul ignore next */
    setVisibleColumns((prevState) => ({
      ...prevState,
      [selectedColumn]: {
        ...prevState[selectedColumn],
        visible: !prevState[selectedColumn].visible
      }
    }))
    window.dataLayer.push({
      ...trackingProps,
      interaction_type: 'columns_selector',
      interaction_value: visibleColumns[selectedColumn].visible ? 'disable' : 'enable',
      filter_type: selectedColumn
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FilterButtonContainer data-testid='select-columns-container' onClick={handleClick} ref={columnButton}>
        <FilterButtonText>{'Select Columns'}</FilterButtonText><MenuArrowDown/>
      </FilterButtonContainer>
      {isOpen && (
        <CategoriesContainer data-testid='column-categories-container' ref={columnMenu}>
          {Object.entries(visibleColumns).map((col, i) => (
            <Category onClick={toggleFilter} key={i}>
              <CategoryCheckbox
                data-testid={`${col[0]}-checkbox`}
                type="checkbox"
                name={col[0]}
                onChange={() => {}}
                checked={visibleColumns[col[0]].visible}
                checkboxColor={checkboxColor}
              />
              <CategoryText>
                {col[0]}
              </CategoryText>
            </Category>
          )
          )}
        </CategoriesContainer>
      )}
    </div>
  )
}

const FilterButtonContainer = styled.div<{
  isOpen?: boolean
}>`
  width: 114px;
  height: 14px;
  padding: 9px 12px 9px 12px;
  border: 1px solid #8D8D8D;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  box-sizing: unset;
`

const FilterButtonText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const CategoriesContainer = styled.div`
  border: 1px solid #8D8D8D;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #00295426;
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  position: absolute;
  z-index: 13;
  margin-top: 36px;
  min-width: 178px;
  height: 500px;
  overflow-y: auto;
`

const Category = styled.div`
  padding: 4px 16px 4px 8px;
  gap: 8px;
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.separator.primary.background.value};
    border-radius: 8px;
  }
  box-sizing: unset;
`

const CategoryCheckbox = styled.input<{
  checkboxColor: string
}>`
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.color.dropdown.border.value};
  border-radius: 2px;
  display: flex !important;
  margin: 0px;
  cursor: pointer;
  accent-color: ${({ checkboxColor }) => checkboxColor};
`

const CategoryText = styled.label`
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 0px;
  cursor: pointer;
`
