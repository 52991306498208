import { useState } from 'react';
import { useAlerts } from '../../../../hooks/useAlerts';
import BulkAlertEarningsMobileView from './BulkAlertEarningsMobileView';
import BulkAlertMacroMobileView from './BulkAlertMacroMobileView';
import style from './BulkAlertMobileData.module.css';
import { bulkAlertTabs } from '../../../../utils/alertUtils';

export default function BulkAlertMobileDataIndividual({ data, text }) {
  const [viewMoreStates, setViewMoreStates] = useState({});
  const { activeAlertTab, handleDeleteAllAlerts } = useAlerts();

  const toggleViewMore = (category) => {
    setViewMoreStates((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const isViewMore = viewMoreStates[text];

  return (
    <div>
      <div className={style.bulkContainer}>
        <p className={style.headTitle}>{bulkAlertTabs[text]}</p>
        <button className={style.deleteAllBtn} onClick={() => handleDeleteAllAlerts(text)}>
          Delete all
        </button>
      </div>
      {activeAlertTab !== 'alertSettings' &&
        data &&
        data.length > 0 &&
        (text === 'macroeconomicEvents' ? (
          <BulkAlertMacroMobileView alertItems={isViewMore ? data : data.slice(0, 3)} text={text} />
        ) : (
          <BulkAlertEarningsMobileView
            alertItems={isViewMore ? data : data.slice(0, 3)}
            text={text}
          />
        ))}

      {!isViewMore && data?.length > 3 && (
        <button className={style.viewMoreBtn} onClick={() => toggleViewMore(text)}>
          View more ({data?.length - 3})
        </button>
      )}
      {isViewMore && (
        <button className={style.viewMoreBtn} onClick={() => toggleViewMore(text)}>
          View less
        </button>
      )}
    </div>
  );
}
