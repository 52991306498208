// @ts-nocheck

import { FlagIcon } from 'react-flag-kit';
import ImportanceBadge from '../../../Global/ImportanceBadge';
import styles from './BulkAlertMobileView.module.css'; // Import CSS module
import { countryCodeToNameMap } from '../../../../utils/countryCodeNameMapping';
import PlaceholderFlag from '../../../../assets/svgs/PlaceholderFlag';
import { supportedCountryCodes } from '../../../../utils/supportedFlagIconList';
import BulkAlertDelete from '../../../../assets/svgs/BulkAlertDelete';
import { useAlerts } from '../../../../hooks/useAlerts';

// Utility function to format end date
const formatEndDate = (endDate) => {
  if (!endDate) return 'No end date';
  const [year, month, day] = endDate;
  return `${new Date(year, month - 1, day).toDateString()}`;
};

// Main Component
const BulkAlertMacroMobileView = ({ alertItems, text }) => {
  const { handleDeleteEconBulkAlertsData } = useAlerts();
  const getCountryCode = (name) => {
    for (const [key, value] of Object.entries(countryCodeToNameMap)) {
      if (value === name) {
        return key;
      }
    }
    return undefined; // Explicit return when not found
  };

  return (
    <div className={styles.alertList}>
      {alertItems.map((item, index) => (
        <div key={index} className={styles.alertItem}>
          <div className={styles.bulkItemSpacing}>
            <div className={styles.rowItem}>
              <ImportanceBadge text={item.importance} />
              <span className={styles.flag}>
                {supportedCountryCodes?.includes(getCountryCode(item.country)) ? (
                  <FlagIcon code={getCountryCode(item.country)} loading="lazy" />
                ) : (
                  <PlaceholderFlag />
                )}
              </span>
              <span className={styles.country}>{item.country}</span>
            </div>
            {item.endDate && item.endDate.length > 0 && (
              <div className={styles.endDate}>
                End date: <span> {formatEndDate(item.endDate)} </span>{' '}
              </div>
            )}
          </div>
          <button
            className={styles.deleteButton}
            onClick={() => handleDeleteEconBulkAlertsData(item.alert, text)}
          >
            <BulkAlertDelete />
          </button>
        </div>
      ))}
    </div>
  );
};

export default BulkAlertMacroMobileView;
