
type FormDataOutput<K extends string[]> = {
  [key in K[number]]: string | null
}

export default function getFormData<T extends string[]>(form: EventTarget, ...fields: T): FormDataOutput<T> {
  let output: Partial<FormDataOutput<T>> = {}

  for (let field of fields) {
    const formField = <HTMLInputElement | undefined> form[field];
    if (formField) {
      output[field] = formField.value;
    } else {
      output[field] = null;
    }
  }

  return output as FormDataOutput<T>;
  
}