import styled from 'styled-components'
import { consensus, investorSentiment, newsSentiment, signal, mediaBuzz } from '../utils/mappings'
import { currencyFormatter } from '@ig-caa/utils'
import Circle from '../components/Circle'
import fetchEpic from '../utils/fetchEpic'
import TetherComponent from 'react-tether'

export const marketNameTD = (
  tradingInformationData,
  bgColor,
  appWidth,
  cst,
  env,
  accountId,
  selected,
  setSelected,
  unavailable,
  setUnavailable,
  fetchEpicError,
  setFetchEpicError,
  launchMarketPanel,
  platform,
  trackingProps
) => {
  const openMarket = async (ticker) => {
    /* istanbul ignore next */
    const country = tradingInformationData.stockListingCountry === 'US'
      ? 'united states'
      : tradingInformationData.stockListingCountry === 'UK' ? 'united kingdom' : tradingInformationData.stockListingCountry.toLowerCase()
    try {
      const epic = await fetchEpic(env, cst, accountId, tradingInformationData.stockISIN, country)
      if (epic === 'Unavailable') {
        setSelected(ticker)
        setUnavailable(true)
        setFetchEpicError(false)
        /* istanbul ignore next */
        setTimeout(() => setUnavailable(false), 2500)
        window.dataLayer.push({ ...trackingProps, interaction_type: 'trade_market', instrument_epic_id: `${epic}`, instrument_ticker: `${ticker}` })
        return
      }
      window.dataLayer.push({ ...trackingProps, interaction_type: 'trade_market', instrument_epic_id: `${epic}`, instrument_ticker: `${ticker}`})
      setSelected(ticker)
      setUnavailable(false)
      setFetchEpicError(false)
      launchMarketPanel(epic)
    } catch (error) {
      setSelected(ticker)
      setFetchEpicError(true)
      /* istanbul ignore next */
      setTimeout(() => setFetchEpicError(false), 2500)
    }
  }

  return (
    <TetherComponent
      attachment='center left'
      targetAttachment='center right'
      style={{zIndex: '3'}}
      renderTarget={(ref) =>
        <TD bgColor={bgColor} title={tradingInformationData.stockDisplayName} ref={ref}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon src={`https://tr-cdn.tipranks.com/static/v2/static/logos/${tradingInformationData.stockListingTicker}.svg`}
              alt={`${tradingInformationData.stockDisplayName}-icon`}
            />
            <NameContainer>
              <Ticker>{tradingInformationData.stockListingTicker.replace(/^[^:]*:/, '')}</Ticker>
              <Name appWidth={appWidth}>{tradingInformationData.stockDisplayName}</Name>
            </NameContainer>
            {platform === 'web' &&
            <TradeButtonContainer data-testid={`trade-${tradingInformationData.stockListingTicker}`} onClick={() => openMarket(tradingInformationData.stockListingTicker)}>
              <TradeLink>Trade</TradeLink>
            </TradeButtonContainer>}
          </div>
        </TD>
      }
      renderElement={(ref) =>
        ((unavailable && selected === tradingInformationData.stockListingTicker) &&
        <ErrorText ref={ref} data-testid={`${tradingInformationData.stockListingTicker}-market-unavailable-popup`}>
          Market unavailable. Please contact our helpdesk to submit your interest.
        </ErrorText>) ||
        ((fetchEpicError && selected === tradingInformationData.stockListingTicker) &&
        <ErrorText ref={ref} data-testid={`${tradingInformationData.stockListingTicker}-fetch-epic-error-popup`}>
          We are unable to complete this request right now. Please try again later.
        </ErrorText>)}
    />
  )
}

export const priceTD = (tradingInformationData, locale, width) => (
  <TD width={width}>{tradingInformationData.stockListingLastClose
    ? currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tradingInformationData.stockListingLastClose, 2)
    : '-'}</TD>
)

export const sectorTD = (tradingInformationData, width) => (
  <TD width={width}>{tradingInformationData.stockSectorName ? tradingInformationData.stockSectorName : '-'}</TD>
)

export const marketCapTD = (tradingInformationData, locale, width) => (
  <TD width={width}>{tradingInformationData.stockListingMarketCap && tradingInformationData.stockListingMarketCapCurrencyCode
    ? currencyFormatter(locale, tradingInformationData.stockListingMarketCapCurrencyCode, tradingInformationData.stockListingMarketCap, 2)
    : '-'}</TD>
)

export const smartScoreTD = (tipRanksEssentialData, colShort, colLong, colPrimary, circleTextCol) => (
  tipRanksEssentialData.stockTipranksSmartScore
    ? <TDCircle>
        <Circle
          bgColor={circleBgColor(tipRanksEssentialData.stockTipranksSmartScore, colShort, colLong)}
          textColor={circleTextColor(tipRanksEssentialData.stockTipranksSmartScore, colPrimary, circleTextCol)}
          score={tipRanksEssentialData.stockTipranksSmartScore}
          classname='main-circle'
        />
      </TDCircle>
    : <TDCircle>-</TDCircle>
)

export const dividendYieldTD = (dividendData, tradingInformationData, locale, width) => (
  <TD width={width}>{dividendData.stockListingLastFiscalDividendRate && dividendData.stockListingDividendYield
    ? `${currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, dividendData.stockListingLastFiscalDividendRate, 2)}
    (${dividendData.stockListingDividendYield.toFixed(2)}%)`
    : '-'}
  </TD>
)

export const analystPriceTargetTD = (tipRanksEssentialData, tradingInformationData, locale, width) => (
  <TD width={width}>{tipRanksEssentialData.stockPriceTarget
    ? tipRanksEssentialData.stockUpside > 0
      ? <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PositiveValue>{currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tipRanksEssentialData.stockPriceTarget, 2)}</PositiveValue>
        ({(tipRanksEssentialData.stockUpside * 100).toFixed(2)}% Upside)
        </div>
      : <div style={{ display: 'flex', flexDirection: 'column' }}>
          <NegativeValue>{currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tipRanksEssentialData.stockPriceTarget, 2)}</NegativeValue>
        ({Math.abs(tipRanksEssentialData.stockUpside * 100).toFixed(2)}% Downside)
        </div>
    : '-'}
  </TD>
)

export const volumeTD = (dividendData, locale, width) => (
  <TD width={width}>{dividendData.stockListingLastVolume
    ? new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(dividendData.stockListingLastVolume)
    : '-'}</TD>
)

export const avg3MVolumeTD = (tradingInformationData, locale, width) => (
  <TD width={width}>{tradingInformationData.stockListingAverage3MVolume
    ? new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(tradingInformationData.stockListingAverage3MVolume)
    : '-'}</TD>
)

export const peRatioTD = (tradingInformationData, width) => (
  <TD width={width}>{tradingInformationData.stockListingPERatio ? tradingInformationData.stockListingPERatio.toFixed(2) : '-'}</TD>
)

export const analystConsensusTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockAnalystConsensus
    ? textColour(consensus[tipRanksEssentialData.stockAnalystConsensus])
    : '-'}
  </TD>
)

export const newsSentimentTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockNewsSentiment
    ? textColour(newsSentiment[tipRanksEssentialData.stockNewsSentiment])
    : '-'}
  </TD>
)

export const mediaBuzzTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockNewsBuzz || tipRanksEssentialData.stockNewsBuzz === 0
    ? textColour(mediaBuzz[Math.ceil(tipRanksEssentialData.stockNewsBuzz * 10)])
    : '-'}
  </TD>
)

// export const priceChartTD = () => (<TD></TD>)

export const bestAnalystConsensusTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockBestAnalystConsensus
    ? textColour(consensus[tipRanksEssentialData.stockBestAnalystConsensus])
    : '-'}
  </TD>
)
export const bestAnalystPriceTargetTD = (tipRanksEssentialData, tradingInformationData, locale, width) => (
  <TD width={width}>{tipRanksEssentialData.stockBestPriceTarget
    ? tipRanksEssentialData.stockBestUpside > 0
      ? <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PositiveValue>{currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tipRanksEssentialData.stockBestPriceTarget, 2)}</PositiveValue>
        ({(tipRanksEssentialData.stockBestUpside * 100).toFixed(2)}% Upside)
        </div>
      : <div style={{ display: 'flex', flexDirection: 'column' }}>
          <NegativeValue>{currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tipRanksEssentialData.stockBestPriceTarget, 2)}</NegativeValue>
        ({Math.abs(tipRanksEssentialData.stockBestUpside * 100).toFixed(2)}% Downside)
        </div>
    : '-'}
  </TD>
)
export const insiderSignalTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockInsiderSignal
    ? textColour(signal[tipRanksEssentialData.stockInsiderSignal])
    : '-'}
  </TD>
)
export const bloggerConsensusTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockBloggerConsensus
    ? textColour(tipRanksEssentialData.stockBloggerConsensus)
    : '-'}
  </TD>
)
export const investorSentimentTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockInvestorSentimentScore
    ? textColour(investorSentiment[tipRanksEssentialData.stockInvestorSentimentScore])
    : '-'}
  </TD>
)
export const hedgeFundSignalTD = (tipRanksEssentialData, width) => (
  <TD width={width}>{tipRanksEssentialData.stockHedgeFundSignal
    ? textColour(signal[tipRanksEssentialData.stockHedgeFundSignal])
    : '-'}
  </TD>
)

const priceIndicatorPercentFunc = (low, high, price) => {
  const diff = high - low
  const priceVLow = price - low
  return (priceVLow / diff) * 100
}

export const fiftyTwoWeekTD = (tradingInformationData, locale, width) => (
  <TD width={width}>
    {tradingInformationData.stockListingLow52Week && tradingInformationData.stockListingHigh52Week && tradingInformationData.stockListingLastClose
      ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '10px' }}>
          <div style={{ width: '75px' }}>
            {currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tradingInformationData.stockListingLow52Week, 2)}
          </div>
          <FiftyTwoWeekRectangle>
            <PriceIndicator
              percent={priceIndicatorPercentFunc(tradingInformationData.stockListingLow52Week, tradingInformationData.stockListingHigh52Week, tradingInformationData.stockListingLastClose)}>
            </PriceIndicator>
          </FiftyTwoWeekRectangle>
          <div style={{ width: '75px', textAlign: 'left' }}>
            {currencyFormatter(locale, tradingInformationData.stockListingCurrencyTypeCode, tradingInformationData.stockListingHigh52Week, 2)}
          </div>
        </div>
      : '-'}
  </TD>
)

const options = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}

export const earningsDateTD = (earningsData, locale, width) => (
  <TD width={width}>
    {earningsData.stockUpcomingEarningsDate && earningsData.stockEarningExpectedReleaseTimeId
      ? <>
          <div>
            {(new Date(earningsData.stockUpcomingEarningsDate)).toLocaleDateString(locale, options)}
          </div>
          <div>
            {earningsData.stockEarningExpectedReleaseTimeId === 'PreMarket'
              ? 'Before Open'
              : earningsData.stockEarningExpectedReleaseTimeId === 'AfterHours' ? 'After Close' : 'TBA' }
          </div>
        </>
      : '-'}
  </TD>
)

export const exDivDateTD = (dividendData, locale, width) => (
  <TD width={width}>
    {dividendData.stockLatestExDividendDate
      ? `${(new Date(dividendData.stockLatestExDividendDate)).toLocaleDateString(locale, options)}`
      : '-'}
  </TD>
)

export const pBRatioTD = (technicalsData, locale, width) => (
  <TD width={width}>{technicalsData.stockListingPtbRatio
    ? new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(technicalsData.stockListingPtbRatio)
    : '-'}</TD>
)

export const pSRatioTD = (technicalsData, locale, width) => (
  <TD width={width}>{technicalsData.stockListingPriceToSalesRatio
    ? new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(technicalsData.stockListingPriceToSalesRatio)
    : '-'}</TD>
)

export const pFCFRatioTD = (technicalsData, locale, width) => (
  <TD width={width}>{technicalsData.stockListingPfcfRatio
    ? new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(technicalsData.stockListingPfcfRatio)
    : '-'}</TD>
)

export const oneDayPerfTD = (tradingInformationData, width) => (
  <TD width={width}>
    {tradingInformationData.stockPriceChangePct
      ? tradingInformationData.stockPriceChangePct > 0
        ? <PositiveValue>{`${(tradingInformationData.stockPriceChangePct * 100).toFixed(2)}%`}</PositiveValue>
        : <NegativeValue>{`${(tradingInformationData.stockPriceChangePct * 100).toFixed(2)}%`}</NegativeValue>
      : '-'}
  </TD>
)

export const oneMonthPerfTD = (performanceData, width) => (
  <TD width={width}>
    {performanceData.stockOneMonthGain
      ? performanceData.stockOneMonthGain > 0
        ? <PositiveValue>{`${(performanceData.stockOneMonthGain * 100).toFixed(2)}%`}</PositiveValue>
        : <NegativeValue>{`${(performanceData.stockOneMonthGain * 100).toFixed(2)}%`}</NegativeValue>
      : '-'}
  </TD>
)

export const oneYearPerfTD = (performanceData, width) => (
  <TD width={width}>
    {performanceData.stockYearlyGain
      ? performanceData.stockYearlyGain > 0
        ? <PositiveValue>{`${(performanceData.stockYearlyGain * 100).toFixed(2)}%`}</PositiveValue>
        : <NegativeValue>{`${(performanceData.stockYearlyGain * 100).toFixed(2)}%`}</NegativeValue>
      : '-'}
  </TD>
)

// STYLING

const NegativeValue = styled.span`color: ${({ theme }) => theme.color.text.negativeAmount.value};`

const PositiveValue = styled.span`color: ${({ theme }) => theme.color.text.positiveAmount.value};`

const NeutralValue = styled.span`color: ${({ theme }) => theme.color.text.tertiary.value};`

const textColour = (category) => {
  if (category.includes('Bullish') || category.includes('Buy') || category.includes('Positive') || category.includes('High')) {
    return <PositiveValue>{category}</PositiveValue>
  }
  if (category === 'Neutral' || category === 'Hold' || category === 'Medium') {
    return <NeutralValue>{category}</NeutralValue>
  }
  return <NegativeValue>{category}</NegativeValue>
}

const circleTextColor = (score, colPrimary, inverted) => {
  return (score >= 3 && score <= 9) ? colPrimary : inverted
}

const circleBgColor = (score, colShort, colLong) => {
  let circleBgColor = score <= 5 ? colShort : colLong

  // background circleBgColor opacity hex value
  if (score === 2 || score === 9) circleBgColor += 'CC' // 80%
  else if (score === 3 || score === 8) circleBgColor += '99' // 60%
  else if (score === 4 || score === 7) circleBgColor += '66' // 40%
  else if (score === 5 || score === 6) circleBgColor += '33' // 20%

  return circleBgColor
}

const Icon = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-top: 4px;
  margin-right: 9px;
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Ticker = styled.div`
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  width: ${({ appWidth }) => appWidth > 400 ? '110px' : '75px'};
`

const TD = styled.td`
  padding: 10px 0px 10px 10px;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  width: ${({ width }) => width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  &:first-child {
    position: sticky;
    z-index: 1;
    left: 0;
    background-color: ${({ bgColor }) => bgColor};
    box-shadow: ${({ bgColor, theme }) => bgColor === theme.color.background.primarySurface.value ? '4px 0px 4px 0px #0000000D' : '4px 0px 4px 0px #00000080'};
  }
`

const TDCircle = styled.td`
  padding: 10px 0 10px 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  line-height: 33px;
`

const FiftyTwoWeekRectangle = styled.div`
  height: 10px;
  width: 75px;
  background: linear-gradient(to left, rgb(43, 112, 174), rgb(183, 33, 34));
  position: relative;
`

const PriceIndicator = styled.div`
  height: 10px;
  width: 3px;
  background: white;
  position: absolute;
  border: 1px solid black;
  left: ${({ percent }) => percent}%;
`

const TradeLink = styled.div`
  color: white;
  cursor: pointer;
  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  padding: 0 5px;
  align-items: center;
; 
`

const TradeButtonContainer = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  background: rgb(32, 110, 180);
  cursor: pointer;
  height: 20px;
; 
`

const ErrorText = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 10px;
  border: 1px solid ${({ theme }) => theme.color.tooltip.warning.border.value};
  background-color: ${({ theme }) => theme.color.tooltip.warning.background.value};
  color: ${({ theme }) => theme.color.tooltip.text.value};
  width: 150px;
  padding: 0px 8px;
`
