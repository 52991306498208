import { useEffect, useState, useRef } from 'react';
import styles from '../styles/Alerts/BulkAlertsSearch.module.css';
import { useEconCalendarParams } from './use-econ-calendar-params';
import SearchIconIos from '../assets/svgs/searchIconIos';
import CrossIconCalendarTypeIos from '../assets/svgs/CrossIconCalendarTypeIos';

const useBulkAlertsSearch = (data, onCompanySelected) => {
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  // Ref to track if the update was from a suggestion selection
  const isSelectingSuggestion = useRef(false);

  useEffect(() => {
    setCompanyName(debouncedInputValue.trim());
  }, [debouncedInputValue]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(companyName);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [companyName]);

  // Update filtered suggestions whenever the input changes
  useEffect(() => {
    if (companyName && !isSelectingSuggestion.current) {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().includes(companyName.toLowerCase()),
      );
      setFilteredSuggestions(filtered);
      setShowDropdown(true);
    } else {
      setFilteredSuggestions([]);
      setShowDropdown(false);
    }

    // Reset the ref after each useEffect run
    isSelectingSuggestion.current = false;
  }, [companyName, data]);

  const handleSelectSuggestion = (suggestion) => {
    setCompanyName(suggestion.name);
    setShowDropdown(false); // Hide dropdown after selection
    isSelectingSuggestion.current = true; // Indicate selection
    onCompanySelected(suggestion.name);
  };

  return (
    <div className={notWebsite ? styles.searchContainerMobile : styles.searchContainer}>
      {notWebsite && (
        <span className={styles.searchIcon}>
          <SearchIconIos />
        </span>
      )}
      <input
        type="text"
        placeholder="Search for a company"
        className={`${notWebsite ? styles.searchCompanyMobile : styles.searchCompany} ${companyName.length === 0 ? styles.noCrossIcon : styles.none}`}
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        data-testid="search_input_earnings"
        aria-label="Search for a company"
      />
      {notWebsite && companyName.length > 0 && (
        <button onClick={() => setCompanyName('')} className={styles.clearBtn}>
          <CrossIconCalendarTypeIos />
        </button>
      )}
      {showDropdown && filteredSuggestions.length > 0 && (
        <ul className={styles.dropdown}>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSelectSuggestion(suggestion)}
              className={styles.dropdownItem}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default useBulkAlertsSearch;
