import React from 'react';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import type { GatsbyBrowser } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import 'systemjs';

import theme from './src/styles/theme';

// This resolves an issue where font-awesome icons will load super large before
// shifting back down to their proper size upon page load
config.autoAddCss = false;

// Solution to Resolve known issue with Page scrolling to top when using Gatsby
// + react-scrollable-anchor: https://github.com/gatsbyjs/gatsby/issues/11898
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    return false;
  }

  return [0, 0];
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};
