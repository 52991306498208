import { useMutation } from "@tanstack/react-query";
import { postLoginForm } from "../utils/api";


export default function useLoginWithRedirect() {
  const loginMutation = useMutation((credentials: Credentials) => {
    return postLoginForm(credentials);
  });

  if (loginMutation.isSuccess && loginMutation.data.redirectUrl) {
    window.location.replace(loginMutation.data.redirectUrl);
  }

  return loginMutation;
}