import './index.scss';
import { useEffect, useState } from 'react';
import initialiseI18n from '../i18n';
import { SessionDataProvider } from './hooks/use-session-data';
import { THEME_CLASSES, EconCalendarParamsProvider } from './hooks/use-econ-calendar-params';
import { getEnvURLs } from './utils/env-utils';
import EconCalendar from './components/EconCalendar';
import { EconCalendarProvider } from './hooks/useEconCalendar';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-popper-tooltip/dist/styles.css';
import { tabsTitles } from './utils/tabsDataAccess-utils';
import { DEFAULT_LOCALE } from './constants/constant';
import { EconAlertProvider } from './hooks/useAlerts';

export default function Root(props) {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const envURLs = getEnvURLs(props.env || props.econPreferences?.preferences?.env);

  // @ts-ignore
  window.dataLayer = window.dataLayer || [];

  const igCompanySiteId =
    props?.sessionData?.igCompany || props.econPreferences?.preferences?.igCompany;

  const getCurrentLocale = (locale) => {
    if (!locale) return null;
    return locale.includes('_') ? locale.split('_')[0] : locale.split('-')[0];
  };

  useEffect(() => {
    const metaViewport = document.querySelector("meta[name='viewport']");
    if (metaViewport) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0',
      );
    } else {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content =
        'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0';
      document.head.appendChild(meta);
    }
  }, []);

  const fullLocale = (
    props?.sessionData?.clientLocale ||
    props.econPreferences?.preferences?.currentLocale ||
    'en_GB'
  )
    .split('_')
    .join('-');

  const locale =
    fullLocale && envURLs.supportedLocaleList.includes(getCurrentLocale(fullLocale))
      ? getCurrentLocale(fullLocale)
      : DEFAULT_LOCALE;

  useEffect(() => {
    initialiseI18n(locale).then(() => {
      setTranslationsLoaded(true);
    });
  }, [locale]);

  if (!translationsLoaded) {
    return null;
  }

  const clientId = props.sessionData.clientId;

  const currentAccountId = !props.sessionData ? null : props.sessionData.currentAccountId;
  const themeProp =
    props.theme?.toUpperCase() || props.econPreferences?.preferences?.currentTheme?.toUpperCase();
  const theme = !THEME_CLASSES[themeProp] ? THEME_CLASSES.DEFAULT : THEME_CLASSES[themeProp];
  const oldEconCalendarUrl = props.calendarUrl;
  const isWebsiteFlag =
    props?.sessionData?.isWebsite || props?.econPreferences?.preferences?.isWebsite;
  const isWebsite = isWebsiteFlag || false;
  const eventIdForDetails = props?.econPreferences?.preferences?.eventId;

  const workspaceId = props.workspaceId || 'WEBSITES';
  const authString = btoa(`${envURLs.tastyUserName}:${envURLs.tastyUserPassword}`);

  const timezoneOffset = null;

  const isIos = props.econPreferences?.preferences?.isIos || false;
  const isIpad = props.econPreferences?.preferences?.isIpad || false;
  const isAndroid = props.econPreferences?.preferences?.isAndroid || false;

  const isAlertsEnabled = envURLs.isAlertsEnabled;
  const alertsApiUrl = envURLs.alertsApiUrl;

  const specifySpecificTab =
    (tabsTitles[props.econPreferences?.preferences?.specifySpecificTab] &&
      props.econPreferences?.preferences?.specifySpecificTab) ||
    '';

  const backButtonHandler =
    (props.backButtonHandler && window.webkit?.messageHandlers?.[props.backButtonHandler]) ||
    window.AndroidBridge ||
    null;

  const handleAddToCalendar =
    (props.handleAddToCalendar && window.webkit?.messageHandlers?.[props.handleAddToCalendar]) ||
    window.AndroidBridge ||
    null;

  const handleEventIdForDetails =
    (props.handleEventIdForDetails &&
      window.webkit?.messageHandlers?.[props.handleEventIdForDetails]) ||
    window.AndroidBridge ||
    null;

  return (
    <SessionDataProvider
      currentAccountId={currentAccountId}
      apiHost={envURLs.apiHost}
      xst={props.xst}
      cst={props.cst}
      calendarApiUrl={envURLs.calendarApiUrl}
      timezoneOffset={timezoneOffset}
      igCompanySiteId={igCompanySiteId}
      environmentId={props.env || props.econPreferences?.preferences?.env}
      clientId={clientId}
    >
      <EconCalendarParamsProvider
        theme={theme}
        locale={locale}
        fullLocale={fullLocale}
        isWebsite={isWebsite}
        isAndroid={isAndroid}
        isIos={isIos}
        isIpad={isIpad}
        specifySpecificTab={specifySpecificTab}
        workspaceId={workspaceId}
        handleAddToCalendar={handleAddToCalendar}
        backButtonHandler={backButtonHandler}
        eventIdForDetails={eventIdForDetails}
        handleEventIdForDetails={handleEventIdForDetails}
      >
        <EconCalendarProvider authString={authString} oldEconCalendarUrl={oldEconCalendarUrl}>
          <EconAlertProvider isAlertsEnabled={isAlertsEnabled} alertsApiUrl={alertsApiUrl}>
            <EconCalendar />
          </EconAlertProvider>
        </EconCalendarProvider>
      </EconCalendarParamsProvider>
    </SessionDataProvider>
  );
}
