import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../../styles/TableRows.scss';
import styles from './CustomDateInput.module.css'; // Import the CSS module
import CalendarIconAlerts from '../../../../assets/svgs/CalendarIconAlerts';
import CalendarIconDateSelection from '../../../../assets/svgs/CalendarIconDateSelection';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';

const CustomDateInput = ({ selectedDate, setSelectedDate, notWebsite, formatDate }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false); // Toggle calendar visibility
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isIos, currentTheme } = useEconCalendarParams();
  const calendarBackgroundStyle = currentTheme === 'dark' ? styles.darkTheme : styles.whiteTheme;
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateCalendarVisibility = (value) => {
    setIsCalendarOpen(value);
  };

  return (
    <div className={notWebsite ? styles.continerMobile : styles.container} ref={dropdownRef}>
      {/* Custom Input */}
      <div
        className={notWebsite ? styles.dateInputMobile : styles.dateInput}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        tabIndex={0}
        role="button"
      >
        <div className={styles.iconContainer}>
          <span className={styles.calendarIcon}>
            {notWebsite ? (
              <CalendarIconDateSelection />
            ) : (
              <CalendarIconAlerts width={16} height={16} />
            )}
          </span>
          {!notWebsite && selectedDate && (
            <span className={styles.dateText}>{formatDate(selectedDate)}</span>
          )}
        </div>
        {!notWebsite && <span className={styles.arrowIcon}>▼</span>}
      </div>

      {isCalendarOpen && notWebsite && (
        <div
          className={styles.modal}
          onClick={() => updateCalendarVisibility(false)}
          role="button"
        />
      )}

      {/* Calendar */}
      {isCalendarOpen && (
        <div className={notWebsite ? styles.calendarContainerMobile : styles.calendarContainer}>
          <div className={notWebsite ? styles.calendarWrapperMobile : styles.none}>
            <Calendar
              onChange={(selectedDate: Date) => {
                setSelectedDate(selectedDate);
                setIsCalendarOpen(false);
              }}
              value={selectedDate}
              className={`${isIos ? styles.iosCalendar : styles.none} ${calendarBackgroundStyle}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDateInput;
