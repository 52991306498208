/* This file is taken from repository platform
The soruce file can be find in the link:
https://git.iggroup.local/projects/WTP/repos/platform/browse/packages/react-scaffold/src/hooks/use-session.tsx
[accessed 5 Janurary]
*/

import React, { createContext, useContext } from 'react'
import { Account, SessionData } from '../shared-types'
interface ISessionContext extends SessionData {
  currentAccount: Account
  apiHost: string
  cst: string
  xst: string
}

interface SessionProps {
  children: React.ReactNode
  apiHost: string
  cst: string
  xst: string
  sessionData: SessionData
}

export const SessionContext = createContext<ISessionContext>(undefined!)

export const SessionProvider: React.FC<SessionProps> = ({
  children,
  cst,
  xst,
  apiHost,
  sessionData
}) => {
  return (
    <SessionContext.Provider
      value={{
        cst,
        xst,
        apiHost,
        currentAccount: sessionData.accounts.find(
          ({ accountId }) => accountId === sessionData.currentAccountId
        )!,
        ...sessionData
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => useContext(SessionContext)
