import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useEconCalendarParams } from '../../../hooks/use-econ-calendar-params';
import useScreenDimensions from '../../../hooks/useScreenDimension';

const AlertSettingsSkeleton = () => {
  const isMobile = useScreenDimensions();
  return (
    <div style={styles.modal}>
      {/* Modal Header */}
      <div style={styles.header}>
        <Skeleton height={isMobile ? 40 : 20} width={'100%'} />
      </div>

      {/* Default Settings Section */}
      <div style={styles.section}>
        <div style={styles.skeletonContainer}>
          <Skeleton height={isMobile ? 40 : 20} width={'100%'} />
        </div>
        <div style={styles.skeletonContainer}>
          <Skeleton height={isMobile ? 20 : 15} width={'100%'} />
        </div>
        <div style={styles.checkboxRow}>
          <Skeleton circle={true} height={isMobile ? 20 : 15} width={isMobile ? 20 : 15} />
          <Skeleton height={isMobile ? 20 : 15} width={120} />
        </div>
        <div style={styles.checkboxRow}>
          <Skeleton circle={true} height={isMobile ? 20 : 15} width={isMobile ? 20 : 15} />
          <Skeleton height={isMobile ? 20 : 15} width={120} />
        </div>

        {isMobile && (
          <>
            <div style={styles.checkboxRow}>
              <Skeleton circle={true} height={isMobile ? 20 : 15} width={isMobile ? 20 : 15} />
              <Skeleton height={isMobile ? 20 : 15} width={120} />
            </div>
            <div style={styles.checkboxRow}>
              <Skeleton circle={true} height={isMobile ? 20 : 15} width={isMobile ? 20 : 15} />
              <Skeleton height={isMobile ? 20 : 15} width={120} />
            </div>
            <div style={styles.checkboxRow}>
              <Skeleton circle={true} height={isMobile ? 20 : 15} width={isMobile ? 20 : 15} />
              <Skeleton height={isMobile ? 20 : 15} width={120} />
            </div>
          </>
        )}
      </div>

      {/* Alert List Section */}
      <div style={styles.section}>
        <div style={styles.skeletonContainer}>
          <Skeleton height={isMobile ? 40 : 20} width={'100%'} />
        </div>
        <div style={styles.skeletonContainer}>
          <Skeleton height={isMobile ? 20 : 15} width="80%" />
        </div>
        <div style={styles.tableRow}>
          <Skeleton height={isMobile ? 40 : 20} width={100} />
          <Skeleton height={isMobile ? 40 : 20} width={100} />
          <Skeleton height={isMobile ? 40 : 20} width={50} />
        </div>
      </div>

      {/* Footer Buttons */}
      <div style={styles.footer}>
        <div style={styles.skeletonContainer}>
          <Skeleton height={40} width={100} />
        </div>
        <div style={styles.skeletonContainer}>
          <Skeleton height={40} width={100} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  modal: {
    width: '100%',
    padding: '20px',
  },
  header: {
    marginBottom: '20px',
  },
  section: {
    marginBottom: '20px',
  },
  skeletonContainer: {
    marginBottom: '10px',
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '10px',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default AlertSettingsSkeleton;
