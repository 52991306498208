export const formatNumber = (num: number, decimals: number = 2) => +num.toFixed(decimals)

export const formatNumberString = (num?: number, decimals: number = 2) => (num !== undefined)
  ? ((num === 0) ? '0' : (+num).toFixed(decimals))
  : ''

export const firstNumberInString = (input: string | number) => {
  if (typeof input === 'string') {
    return +input.split('(', 1).toString().replace(/[^\d.-]/g, '')
  }
  return input
}
