import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './AlertSettingsTableData.module.css';
import DeleteIconAlerts from '../../../../assets/svgs/DeleteIconAlerts';
import { convertDateToString } from '../../../../utils/util';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useSessionData } from '../../../../hooks/use-session-data';
import EditAlertMobile from '../../../../assets/svgs/EditAlertMobile';
import CrossIconCalendarTypeIos from '../../../../assets/svgs/CrossIconCalendarTypeIos';
import { alertPlatformsMapping, editAlertMobileConfig } from '../../../../utils/alertUtils';
import { useAlerts } from '../../../../hooks/useAlerts';
import { AlertSettingIndividualOptions } from './AlertSettingIndividualOptions';

const AlertSettingsTableData = (props) => {
  const { t } = useTranslation();
  const { locale, isIos, isAndroid } = useEconCalendarParams();
  const { timezoneOffset } = useSessionData();
  const notWebsite = isIos || isAndroid;

  // Original and Edited Data
  const [alertData] = useState(props.data); // Original data
  const [editedAlertData, setEditedAlertData] = useState(props.data); // Editable data

  const [eventName, setEventName] = useState({});
  const [eventAlertIndex, setEventAlertIndex] = useState(0);
  const [showEditAlertScreen, setShowEditAlertScreen] = useState(false);
  const settingsKeys = Object.keys(editAlertMobileConfig);
  const { handleIndividualAlertDelete, handleIndividualAlertUpdate } = useAlerts();

  // Validation Layer - Compare and call API
  const handleCheckboxChange = async (index, field) => {
    const updatedData = [...editedAlertData];

    if (field === 'reoccurring') {
      updatedData[index][field] = !updatedData[index][field];
    } else {
      const channelIndex = updatedData[index].notificationChannel.indexOf(field);
      if (channelIndex > -1) {
        updatedData[index].notificationChannel.splice(channelIndex, 1);
      } else {
        updatedData[index].notificationChannel.push(field);
      }
    }

    setEditedAlertData(updatedData);

    const isValid = validateChange(alertData[index], updatedData[index]);
    if (isValid) {
      await updateAlertRowData(updatedData[index]);
    } else {
      console.error('Validation failed for checkbox change');
    }
  };

  // Validation Logic
  const validateChange = (original, updated) => {
    if (updated.notificationChannel.length === 0) {
      return false;
    }
    return true;
  };

  // Simulated API Call
  const updateAlertRowData = async (updatedRow) => {
    handleIndividualAlertUpdate(updatedRow);
  };

  const handleEditAlert = (eventName, index) => {
    setEventName(eventName);
    setShowEditAlertScreen(true);
    setEventAlertIndex(index);
  };

  const closeEditAlert = () => {
    setEventName({});
    setShowEditAlertScreen(false);
  };

  const updateAlertField = (alertId, field, value) => {
    const updatedData = [...editedAlertData];
    if (field === 'alertRepeatitions') {
      updatedData[eventAlertIndex].reoccurring = !updatedData[eventAlertIndex].reoccurring;
    } else {
      const channelIndex = updatedData[eventAlertIndex].notificationChannel.indexOf(
        alertPlatformsMapping[value],
      );
      if (channelIndex > -1) {
        updatedData[eventAlertIndex].notificationChannel.splice(channelIndex, 1);
      } else {
        updatedData[eventAlertIndex].notificationChannel.push(alertPlatformsMapping[value]);
      }
    }
    setEditedAlertData(updatedData);
  };

  return (
    <div className={!notWebsite ? style.tableContainer : style.tableContainerMobile}>
      <table className={!notWebsite ? style.customTable : style.customTableMobile}>
        {!notWebsite && (
          <thead className={style.tableHead}>
            <tr className={style.tableRow}>
              <th>Event Name</th>
              <th>Date</th>
              <th>Recurring</th>
              <th>Platform</th>
              <th>Push Notification</th>
              <th></th>
            </tr>
          </thead>
        )}
        <tbody>
          {editedAlertData &&
            editedAlertData.length > 0 &&
            editedAlertData.map((row, index) => (
              <tr className={notWebsite ? style.mobileRow : style.websiteRow} key={index}>
                <td>{row.ticker}</td>
                <td className={notWebsite ? style.date : style.none}>
                  {notWebsite && row.date && <span> Date: </span>}
                  {row.date &&
                    convertDateToString(
                      row.date,
                      timezoneOffset,
                      true,
                      locale !== 'en' ? locale : undefined,
                      t,
                    )}
                </td>
                {!notWebsite && (
                  <>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.reoccurring}
                        onChange={() => handleCheckboxChange(index, 'reoccurring')}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.notificationChannel?.includes('TRADING_PLATFORM')}
                        onChange={() => handleCheckboxChange(index, 'TRADING_PLATFORM')}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.notificationChannel?.includes('PUSH')}
                        onChange={() => handleCheckboxChange(index, 'PUSH')}
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => handleIndividualAlertDelete(row)}
                        className={style.deleteIcon}
                      >
                        <DeleteIconAlerts />
                      </button>
                    </td>
                  </>
                )}
                {notWebsite && (
                  <td>
                    <button className={style.editAlert} onClick={() => handleEditAlert(row, index)}>
                      <EditAlertMobile />
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {showEditAlertScreen && (
        <div>
          <div className={style.modal}></div>
          <div className={style.editAlertWrapper}>
            <div className={style.editAlertHeader}>
              <span>{(eventName as { ticker: string })?.ticker}</span>
              <button className={style.closeEditAlertBtn} onClick={closeEditAlert}>
                <CrossIconCalendarTypeIos />
              </button>
            </div>
            <div className={style.editAlertBody}>
              <AlertSettingIndividualOptions
                settingsKeys={settingsKeys}
                alertData={editedAlertData}
                eventAlertIndex={eventAlertIndex}
                updateAlertField={updateAlertField}
              />
            </div>
            <div className={style.buttonsWrapper}>
              <button onClick={() => handleIndividualAlertDelete(eventName)}>Delete alert</button>
              <button onClick={() => handleIndividualAlertUpdate(editedAlertData[eventAlertIndex])}>
                Update changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertSettingsTableData;
