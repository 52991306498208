import style from './ImportanceBadge.module.css';

export default function ImportanceBadge({ text }) {
  return (
    <div
      className={`${style.badgeImportance} ${text?.toLowerCase() === 'high' ? style.highImp : text?.toLowerCase() === 'low' ? style.lowImp : style.medImp}`}
    >
      {text?.[0]}
    </div>
  );
}
