import style from '../../styles/filters/ZoneCountryImportance.module.css';
import { useTranslation } from 'react-i18next';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import CountryFilter from './CountryFilter';
import { supportedCountryCodes } from '../../utils/supportedFlagIconList';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import { continents, getContinentForCountry, getCountryCode } from '../../utils/util';
import { useMemo } from 'react';
import { accounts } from '../../utils/accountRegions';
import { useSessionData } from '../../hooks/use-session-data';
import ArrowExpandIcon from '../../assets/svgs/ArrowExpandIcon';
import EventCollapse from '../../assets/svgs/EventCollapse';
import ExpandFiltersIcon from '../IconComponents/ExpandFiltersIcon';

export default function CountryFilterBox({
  allCountry,
  extendedCountryFilter,
  updateExtendedCountryFilter,
  isAlert = false,
}) {
  const { t } = useTranslation();
  const { isDataLoading, activeTab } = useEconCalendar();

  const toShowImportanceFilterSection = ['events', 'earnings-reports'].includes(activeTab);
  const isMobile = useScreenDimensions();
  const { isIos, isWebsite } = useEconCalendarParams();
  const countriesFiltered = allCountry?.length;
  const { igCompanySiteId } = useSessionData();

  const countryList = useMemo(() => {
    let count = 0;
    const value = accounts[igCompanySiteId] && getCountryCode(accounts[igCompanySiteId]);
    const continentName = getContinentForCountry(igCompanySiteId);

    if (!continents[continentName]?.includes(value[0][0])) {
      continents[continentName]?.push(value[0][0]);
    }

    const flagsToBeShown = [];
    Object.keys(continents).forEach((continent) => {
      const countries = continents[continent];
      if (count >= countriesFiltered || flagsToBeShown.length > 3 || count > 3) return; // Use >= instead of > to ensure correct count
      countries.forEach((country) => {
        if (allCountry && allCountry?.length > 0 && allCountry?.includes(country)) {
          flagsToBeShown.push(country);
          count++;
        }
      });
    });
    return flagsToBeShown;
  }, [allCountry]);

  const totalCountry = Object.values(continents)?.reduce(
    (totalCountry, country) => (totalCountry += country.length),
    0,
  );

  return (
    <div className={style.filtersWrapper}>
      <button
        className={`${style.alignment} ${
          isDataLoading ? style.noCursor : style.cursor
        } ${isAlert && style.alertFilterWrapper} countryFilter`}
        onClick={updateExtendedCountryFilter}
        aria-label={t('Toggle Country Filter')} // ARIA label for accessibility
        aria-expanded={extendedCountryFilter ? 'true' : 'false'} // Indicates if the filter is expanded or not
        data-testid="country_filter_expand"
        disabled={isDataLoading}
      >
        <div
          className={`${style.country} ${style.filterHeading} ${
            isMobile && isIos ? style.iosFilterSection : style.none
          }`}
        >
          <div> {t('Country/Region')} </div>
          {isIos && isMobile && (
            <div className={style.iosAllText}>
              <span>
                {allCountry.length === totalCountry
                  ? t('All')
                  : allCountry.length + ' / ' + totalCountry}
              </span>
              {extendedCountryFilter ? <ArrowExpandIcon /> : <EventCollapse />}
            </div>
          )}
          {!isMobile && (
            <div className={`${isMobile ? style.mobileFlags : style.flags}`}>
              {countryList.slice(0, Math.min(3, countryList.length)).map((countryCode, index) => (
                <div key={index}>
                  <FlagIcon
                    code={
                      supportedCountryCodes?.includes(countryCode as FlagIconCode)
                        ? (countryCode as FlagIconCode)
                        : null
                    }
                  />
                </div>
              ))}
              <div className={style.flagCount}>
                {`${
                  countriesFiltered && countriesFiltered > 3 ? '+' + (countriesFiltered - 3) : ''
                }`}
              </div>
            </div>
          )}
        </div>
        {!isMobile && <ExpandFiltersIcon />}
      </button>
      <div
        className={`${
          toShowImportanceFilterSection
            ? isWebsite
              ? style.countryFilterImportanceWebsite
              : style.countryFilterImportance
            : isWebsite
              ? style.countryFilterNoImportanceWebsite
              : style.countryFilterNoImportance
        } ${
          extendedCountryFilter
            ? toShowImportanceFilterSection
              ? isWebsite
                ? style.countryFilterMarginWebsite
                : style.countryFilterMargin
              : isWebsite
                ? style.countryFilterNoMarginWebsite
                : style.countryFilterNoMargin
            : style.noFilter
        } ${isAlert && style.countryFilterMarginWebsiteAlert}`}
      >
        {extendedCountryFilter && (!isMobile || (isMobile && isIos)) && (
          <CountryFilter
            allCountry={allCountry}
            extendedCountryFilter={extendedCountryFilter}
            updateExtendedCountryFilter={updateExtendedCountryFilter}
          />
        )}
        {isMobile && isIos && extendedCountryFilter && (
          <div className={style.applyBtnIosWrapper}>
            <button className={style.applyBtnIos} onClick={updateExtendedCountryFilter}>
              {t('Ok')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
