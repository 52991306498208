import NoAlertsMobile from '../../../assets/svgs/NoAlertsMobile';
import { noEventScreenMobileConfig } from '../../../utils/alertUtils';
import style from './AlertSettings/AlertSettings.module.css';
const NoAlertsSetMobile = ({ config }) => {
  return (
    <div className={style.noAlertsSection}>
      <NoAlertsMobile />
      <div className={style.textSection}>
        <p> {noEventScreenMobileConfig[config].heading} </p>
        <p> {noEventScreenMobileConfig[config].body} </p>
      </div>
    </div>
  );
};

export default NoAlertsSetMobile;
