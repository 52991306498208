import { useState } from 'react';
import style from './BulkAlertSettings.module.css';
import BulkAlertSettingsTableData from './BulkAlertSettingsTableData';
import BulkAlertsTabs from './BulkAlertsTabs';
import { useAlerts } from '../../../../hooks/useAlerts';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import NoAlertsSetMobile from '../NoAlertsSetMobile';
import BackArrow from '../../../../assets/svgs/BackArrow';
import { bulkAlertEarningsTableRow, bulkAlertMacroTableRow } from '../../../../utils/alertUtils';
import { formatDate } from '../../../../utils/util';
import BulkAlertMobileData from './BulkAlertMobileData';
import BackArrowiOS from '../../../../assets/svgs/BackArrowiOS';

export default function AlertSettings({ activeAlertTab }) {
  const [selectedImportance, setSelectedImportance] = useState([]);
  const [extendedImportanceFilter, setExtendedImportanceFilter] = useState(false);
  const [allCountry, setAllCountry] = useState([]);
  const {
    isAlertsLoading,
    tabAlertData,
    alertsData,
    selectedAlertType,
    setSelectedAlertType,
    showBulkAlertsDrawer,
    setShowBulkAlertsDrawer,
  } = useAlerts();
  const { isAndroid, isIos } = useEconCalendarParams();
  const notWebsite = isAndroid || isIos;

  const [companyName, setCompanyName] = useState('');
  const { submitBulkAlert } = useAlerts();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // State to store the selected date

  const handleImportance = (value) => {
    if (selectedImportance.includes(value)) {
      setSelectedImportance(selectedImportance?.filter((item) => item !== value));
    } else {
      setSelectedImportance((prevState) => [...prevState, value]);
    }
  };

  const handleAlertTypeChange = (e) => {
    setSelectedAlertType(e.target.value);
  };

  const rowsInfo =
    selectedAlertType === 'macroeconomicEvents'
      ? bulkAlertMacroTableRow
      : bulkAlertEarningsTableRow;

  const handleSubmitBulkAlert = () => {
    if (showBulkAlertsDrawer || !notWebsite) {
      if (selectedAlertType === 'macroeconomicEvents') {
        // handle macroeconomic events bulk alerts
        // alert('handling macroeconomicEvents');
        const payload = {
          importance: selectedImportance.map((item) => item.toUpperCase()),
          countryRegion: allCountry,
          endDate: selectedDate && formatDate(selectedDate),
        };
        submitBulkAlert(payload);
      } else if (selectedAlertType === 'earningsEvents') {
        const payload = {
          company: companyName,
          endDate: selectedDate && formatDate(selectedDate),
        };
        submitBulkAlert(payload);
      }
      setShowBulkAlertsDrawer(false);
    } else {
      setShowBulkAlertsDrawer(true);
    }
  };

  return (
    <div
      className={
        showBulkAlertsDrawer && notWebsite
          ? style.bulkAlertSettingsDrawer
          : style.bulkAlertSettingsContainer
      }
    >
      {!showBulkAlertsDrawer && notWebsite && !Object.keys(alertsData).length ? (
        <NoAlertsSetMobile config="bulkAlerts" />
      ) : (
        ((showBulkAlertsDrawer && notWebsite) || !notWebsite) && (
          <>
            {showBulkAlertsDrawer && notWebsite && (
              <div className={style.header}>
                <button className={style.backArrow} onClick={() => setShowBulkAlertsDrawer(false)}>
                  {isIos ? <BackArrowiOS /> : <BackArrow />}
                </button>
                <span className={style.bulkAlertsText}> Bulk Alerts </span>
              </div>
            )}
            <div className={notWebsite ? style.drawerBodySection : style.none}>
              <p className={style.bulkAlertText}>Set bulk alerts for</p>
              <div className={notWebsite ? style.bulkAlertSectionMobile : style.bulkAlertSection}>
                <div
                  className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${isAndroid && style.androidDirection}`}
                >
                  <input
                    type="radio"
                    id="macroeconomicEvents"
                    name="alertType"
                    value="macroeconomicEvents"
                    checked={selectedAlertType === 'macroeconomicEvents'}
                    onChange={handleAlertTypeChange}
                  />
                  <label
                    htmlFor="macroeconomicEvents"
                    className={notWebsite ? style.labelTextMobile : style.labelText}
                  >
                    Macroeconomic events
                  </label>
                </div>
                <div
                  className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${isAndroid && style.androidDirection}`}
                >
                  <input
                    type="radio"
                    id="earningsEvents"
                    name="alertType"
                    value="earningsEvents"
                    checked={selectedAlertType === 'earningsEvents'}
                    onChange={handleAlertTypeChange}
                  />
                  <label
                    htmlFor="earningsEvents"
                    className={notWebsite ? style.labelTextMobile : style.labelText}
                  >
                    Earnings events
                  </label>
                </div>
              </div>
            </div>

            <div>
              <BulkAlertsTabs
                selectedImportance={selectedImportance}
                extendedImportanceFilter={extendedImportanceFilter}
                setExtendedImportanceFilter={setExtendedImportanceFilter}
                handleImportance={handleImportance}
                allCountry={allCountry}
                setAllCountry={setAllCountry}
                selectedAlertType={selectedAlertType}
                showBulkAlertsDrawer={showBulkAlertsDrawer}
                handleSubmitBulkAlert={handleSubmitBulkAlert}
                formatDate={formatDate}
                setCompanyName={setCompanyName}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>
          </>
        )
      )}
      {notWebsite && (
        <div className={style.setBulkAlertsWrapper}>
          <button className={style.bulkAlertBtn} onClick={handleSubmitBulkAlert}>
            Set bulk alerts
          </button>
        </div>
      )}
      {!isAlertsLoading && !showBulkAlertsDrawer ? (
        !notWebsite ? (
          <div className={style.alertDetailContainer}>
            <p className={style.alertDetailText}>
              You have alerts set for {tabAlertData?.length}{' '}
              {activeAlertTab === 'alertSettings'
                ? 'economic events'
                : selectedAlertType === 'macroeconomicEvents'
                  ? 'macroeconomic events'
                  : 'earnings events'}
            </p>
            {tabAlertData && tabAlertData.length > 0 ? (
              <BulkAlertSettingsTableData
                data={alertsData}
                rowsInfo={rowsInfo}
                selectedAlertType={selectedAlertType}
              />
            ) : (
              <div className={style.noEventsDiv}>
                <p className={style.noEventsToDisplay}>No events to display</p>
              </div>
            )}
          </div>
        ) : (
          <BulkAlertMobileData data={alertsData} />
        )
      ) : (
        !showBulkAlertsDrawer && <p className={style.alertDetailText}>Loading page</p>
      )}
    </div>
  );
}
