export const encryptPassword = async (password: string, encryptionKey: string, timeStamp: string) => {
  const encryptedCandidate = `${password}|${timeStamp}`;

  const [pidCrypt, util] = await Promise.all([
    import(/* webpackPrefetch: true */ 'pidcrypt').then((mod) => mod.default),
    import(/* webpackPrefetch: true */ 'pidcrypt/pidcrypt_util').then((mod) => mod.default),
    import(/* webpackPrefetch: true */ 'pidcrypt/rsa'),
    import(/* webpackPrefetch: true */ 'pidcrypt/asn1'),
  ]);

  const rsa = new pidCrypt.RSA();
  const key = util.decodeBase64(encryptionKey);

  const asn = pidCrypt.ASN1.decode(util.toByteArray(key));
  const tree = asn.toHexTree();
  rsa.setPublicKeyFromASN(tree);
  

  return util.encodeBase64(util.convertFromHex(rsa.encrypt(encryptedCandidate)));
}