import Direction from './Direction'
import Size from './Size'
import Resulting from './Resulting'
import '../styles/PnLCalculator.css'
import { Trans, useTranslation } from 'react-i18next'
import { Environment, Directions, GATracking } from '../shared-types'
import { useEffect, useMemo, useState } from 'react'
import { useLsSubscription } from '@ig-wpx/lightstreamer'
import { useSession } from '../hooks/useSession'

export interface PnLCalculatorProps {
  clientLocale: string
  webSiteId: string
  env: Environment
  currencyName: string
  currencySymbol: string
  valueOfOnePip?: number
  onePipMeans?: number
  minContracts: number
  decimalPlacesFactor: number
  epic: string
  isShares: boolean
  scalingFactor: number
  instrumentName: string
  gaTracking: GATracking
}

const productDetailsLink: Record<string, string> = {
  aum: 'https://www.ig.com/au/help-and-support/cfds/fees-and-charges', // Australian CFD
  igi: 'https://www.ig.com/uk/help-and-support/spread-betting-and-cfds/products-markets-and-trading-hours',
  igm: 'https://www.ig.com/uk/help-and-support/spread-betting-and-cfds/products-markets-and-trading-hours'
}

const PRICE_FIELDS = ['bid', 'offer'] as const

export default function PnLCalculator (props: PnLCalculatorProps): JSX.Element {
  const { t } = useTranslation()
  const {
    clientLocale, currencyName, currencySymbol, valueOfOnePip, onePipMeans, minContracts,
    decimalPlacesFactor, webSiteId, epic, isShares, scalingFactor, instrumentName, gaTracking
  } = props

  const [valueSize, setValueSize] = useState<string | undefined>()
  const [valueOpening, setValueOpening] = useState<string | undefined>()
  const [valueClosing, setValueClosing] = useState<string | undefined>()

  useEffect(() => {
    setValueSize(undefined)
    setValueOpening(undefined)
    setValueClosing(undefined)
  }, [epic])

  const [selectedDirection, setSelectedDirection] = useState<Directions>(undefined)

  const { currentAccountId } = useSession()
  const subscriptionItems = useMemo(
    () => [`MARKET_V4:${currentAccountId}:${epic}`],
    [currentAccountId, epic]
  )

  const { data } = useLsSubscription(subscriptionItems, PRICE_FIELDS, 'MERGE')
  const prices = {
    bid: data?.bid ? parseFloat(data.bid) : undefined,
    offer: data?.offer ? parseFloat(data.offer) : undefined
  }

  return (
    <>
      <div className='PnL'>
        <p className='title-instructions' data-testid='title-instructions'>
          {t('title-instructions')}
        </p>
        <Direction
          setSelectedDirection={setSelectedDirection}
          selectedDirection={selectedDirection}
          decimalPlacesFactor={decimalPlacesFactor}
          bid={prices.bid}
          offer={prices.offer}
        />
        <Size
          minContracts={minContracts}
          updateSize={setValueSize}
          updateOpening={setValueOpening}
          updateClosing={setValueClosing}
          valueSize={valueSize}
          valueOpening={valueOpening}
          valueClosing={valueClosing}
          decimalPlacesFactor={decimalPlacesFactor}
          initialValue={selectedDirection === 'sell' ? prices.bid : prices.offer}
          isShares={isShares}
          scalingFactor={scalingFactor}
          epic={epic}
          instrumentName={instrumentName}
          gaTracking={gaTracking}/>
        <Resulting
          clientLocale={clientLocale}
          currencyName={currencyName}
          currencySymbol={currencySymbol}
          valueOfOnePip={isShares ? 1 : valueOfOnePip}
          onePipMeans={isShares ? 1 : onePipMeans}
          valueSize={valueSize ? +valueSize : undefined}
          valueOpening={valueOpening ? +valueOpening : undefined}
          valueClosing={valueClosing ? +valueClosing : undefined}
          selectedDirection={selectedDirection}
          decimalPlacesFactor={decimalPlacesFactor}
          isShares={isShares}
          epic={epic}
          instrumentName={instrumentName}
          gaTracking={gaTracking}/>
        <p className='disclaimer' data-testid='disclaimer'>
          {t('disclaimer')}
          <br />
          <Trans i18nKey='disclaimer-link-text'>
            Please visit our
            <a className='disclaimer-link-text'
              data-testid='disclaimer-link'
              href={productDetailsLink[webSiteId] ?? 'https://www.ig.com/uk/help-and-support/spread-betting-and-cfds/products-markets-and-trading-hours'}
              target="_blank"
              rel="noreferrer">
                product details
            </a>
            for more information.
          </Trans>
        </p>
      </div>
    </>
  )
}
