import React, { MouseEventHandler, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useTranslation } from '@ig-caa/i18n'
import { Directions } from '../shared-types'

const CLASSNAME_DOWN = 'price-tick--down'
const CLASSNAME_DOWN_2 = `${CLASSNAME_DOWN}-2`
const CLASSNAME_UP = 'price-tick--up'
const CLASSNAME_UP_2 = `${CLASSNAME_UP}-2`

function usePrevious<T> (value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

type UsePriceTickClass = (price: number) => string
type UseFormattedPrice = (tickPrice?: number, decimalPlacesFactor?: number) => [string, number]

const usePriceTickClass: UsePriceTickClass = (price) => {
  const previousPrice = usePrevious(price)
  const previousClassRef = useRef('')
  let directionClass = ''
  if (previousPrice) {
    if (price > previousPrice) {
      directionClass =
          previousClassRef.current === CLASSNAME_UP
            ? CLASSNAME_UP_2
            : CLASSNAME_UP
    } else if (price < previousPrice) {
      directionClass =
          previousClassRef.current === CLASSNAME_DOWN
            ? CLASSNAME_DOWN_2
            : CLASSNAME_DOWN
    }
    previousClassRef.current = directionClass
  }

  return directionClass
}

const useFormattedPrice: UseFormattedPrice = (tickPrice?: number, decimalPlacesFactor?: number) => {
  let priceToString = '-'
  let price = 0
  if ((tickPrice === undefined || isNaN(tickPrice))) {
    priceToString = '-'
    price = 0
  } else {
    priceToString = (tickPrice).toFixed(decimalPlacesFactor)
    price = +priceToString
  }

  return [priceToString, price]
}

const PriceTick: React.FC<{
  className: string
  tickPrice?: number
  direction: Directions
  selectedDirection: Directions
  handleDirectionClick: MouseEventHandler<HTMLButtonElement>
  decimalPlacesFactor?: number
}> = React.memo(
  ({
    className,
    tickPrice,
    direction,
    selectedDirection,
    handleDirectionClick,
    decimalPlacesFactor
  }) => {
    const { t } = useTranslation()
    const [priceToString, price] = useFormattedPrice(tickPrice, decimalPlacesFactor)
    const directionClass = usePriceTickClass(price)
    return (
      <button
        className={
          selectedDirection == direction
            ? `dealing-button ${direction}-selected`
            : `dealing-button ${direction}-unselected`
        }
        data-testid={`${direction}-button`}
        onClick={handleDirectionClick}
      >
        <div className={`${direction}-price-direction`} data-testid={`${direction}-name`}>{t(`${direction}`)}</div>
        <div
          className={`button-${direction}-price`}
          data-testid={`${direction}-price`}
        >
          <span className={classNames('price-tick', directionClass, className)} data-testid={`${direction}`}>
            {priceToString}
          </span>
        </div>
        <div
          className={
            selectedDirection == direction
              ? `${direction}-price icon-container-selected`
              : `${direction}-price icon-container-unselected`
          }
          data-testid={`${direction}-price-icon-container`}
        >
          <div
            className={classNames(directionClass, selectedDirection === direction ? 'selected' : 'unselected')}
            data-testid={`${direction}-directionClass`}
          ></div>
        </div>
      </button>
    )
  }
)
PriceTick.displayName = 'PriceTick'
export default PriceTick
